import { axiosInst } from "../main";

export default class JournalPublisherService {
    
    async fetchAll( params )  {
        const response = await axiosInst.get('/publishers', {
            params: params
        })
        return response
    }

    async get( id, params )  {
        let path = `/publishers/${id}`
        const response = await axiosInst.get( path, {
            params: params
        })
        return response
    }

    async create( data, params )  {
        let path = `/publishers`
        const response = await axiosInst.post( path, data, {
            params: params
        })
        return response
    }

    async update( id, data, params )  {
        let path = `/publishers/${id}`
        const response = await axiosInst.put( path, data, {
            params: params
        })
        return response
    }
}