<template>
	<v-container fluid>
		<v-row no-gutters>
			<v-col cols="3">
				<author-picker v-model="pickerData"></author-picker>
			</v-col>
			<v-divider vertical></v-divider>
			<v-col>
				<v-toolbar flat v-if="toolbarTitle != ''">
					<v-toolbar-title class="h3">{{ toolbarTitle }}</v-toolbar-title>
					<v-spacer></v-spacer>
					<v-menu offset-y class="ml-2">
							<template v-slot:activator="{on, attrs}">
								<v-btn v-bind="attrs" v-on="on">
									Exporty a sestavy
								</v-btn>
							</template>
							<v-list>
								<v-list-item link @click="exportImports()">
									Exportovat
								</v-list-item>
							</v-list>
						</v-menu>
				</v-toolbar>
				<div v-if="!hasData">
					<v-alert type="info" dense outlined>Pro vybrané zobrazení nelze zobrazit data, vyberte podřízenou položku.</v-alert>
				</div>
				<v-data-table v-if="hasData" :headers="headers" 
					:items="publicationStore.items" :items-per-page="15"
					> 
					<template v-slot:item.title="{item}">
						{{ item.title }}
						<v-chip x-small color="green" dark v-if="item.earlyAccess">
							<v-icon>mdi-clock-outline</v-icon>
							Early access</v-chip>
						<v-chip x-small color="gray">{{ item.importDate }}</v-chip>
					</template>
					<template v-slot:item.imported="{item}">
						<v-icon v-if="item.stateId==3 ">mdi-check-circle</v-icon>
						<v-icon v-if="item.stateId==2 ">mdi-progress-helper</v-icon>
					</template>
					<template v-slot:item.source="{item}">
						<div>
						<v-chip v-if="item.wos" small :color="getChipColor(item.wosStatusId)" dark>
							<v-icon>mdi-alpha-w</v-icon>
							<v-progress-circular v-if="item.wosStatusId==1" class="ml-1" indeterminate size="15" width="1"></v-progress-circular>
							<v-icon small class="ml-1" v-if="item.wosStatusId==2">mdi-check</v-icon>
						</v-chip>
						<v-chip class="ml-2" v-if="item.scopus" :color="getChipColor(item.scopusStatusId)" small dark>
							<v-icon>mdi-alpha-s</v-icon>
							<v-progress-circular v-if="item.scopusStatusId==1" indeterminate size="15" width="1"></v-progress-circular>
							<v-icon small v-if="item.scopusStatusId==2">mdi-check</v-icon>
						</v-chip>
						</div>
					</template>
					<template v-slot:item.actions="{item}">
						<v-progress-circular v-if="item.stateId!=3 && !isImportable(item)" indeterminate color="primary"></v-progress-circular>
						<v-btn target="_blank"  v-if="item.stateId!=3 && isImportable(item)" icon :to="{name: 'import-publication', params: {id: item.id, backTo: {name: 'publications'}}}"><v-icon>mdi-import</v-icon></v-btn>
						<v-btn   target="_blank" v-if="item.stateId==3 && item.publicationId > 0" icon :to="{name: 'publication-detail', params: {id: item.publicationId}}"><v-icon>mdi-book-open-blank-variant</v-icon></v-btn>
					</template>
				</v-data-table>
			</v-col>
		</v-row>
	</v-container>
</template>

<script> 
import DepartmentService from '../services/department-service';
import { listsStore } from '../stores/lists-store'
import AuthorPicker from './parts/AuthorPicker.vue';
import PublicationView from './PublicationView.vue'
import { publicationStore } from '../stores/publication-store';
import { authStore } from '../stores/user';
import { useWormEntryStore } from '../stores/worm-entry-store';
import PublicationService from '../services/publication-service';

export default {
	components: { AuthorPicker, PublicationView },
	name: 'ImportManager',
	props: {
		value: Object,
	},
	data: () => ({
		usersLoading: false,
		expanded: [],
		viewApprovalEnabled: false,

		listsStore: listsStore(),
		userStore: authStore(),
		publicationStore: useWormEntryStore(),

		pickerData: {},
		authorList: [],
		departmentService: new DepartmentService(),
		publicationService: new PublicationService(),
		authorInEdit: {},

		hasData: false,
		toolbarTitle: "",

		publicationDraftView: {},

		headers: [
			{text: "#", value: 'id'},
			{ text: "Zdroj", value: "source", width: "180px", sortable: false  },
			{text: "Název", value: 'title'},
			{text: "Rok", value: 'year'},
			{ text: "Imp.", value: "imported", sortable: false  },
			{ text: "Akce", value: "actions", width: "120px", sortable: false },
		],
	}),
	mounted: async function() {
		await this.listsStore.fetch();
	},
	watch: {
		pickerData: function(n,o) {
			console.log("picker picked")
			this.loadUsers();
		}
	},
	computed: {
		/*
		treeItems: function() {
			
		}
		*/
	},
	methods: {
		loadUsers: function() {
			this.hasData = false;
			this.toolbarTitle = "";

			if(this.pickerData.state == 2 && this.pickerData?.faculty?.id) {
				this.hasData = false;
				this.toolbarTitle = this.pickerData.faculty.name;
			}

			if(this.pickerData.state == 3 && this.pickerData?.pickedAuthor?.uic) {
				this.hasData = true;
				this.publicationStore.fetchForAuthor(this.pickerData.pickedAuthor.uic)

			} else if(this.pickerData.state == 2 && this.pickerData?.baseUnit?.id ) {
				this.hasData = true;
				this.publicationStore.fetchForDepartment( this.pickerData.baseUnit.id )
				this.toolbarTitle = this.pickerData.baseUnit.name
			}
		},
		itemExpanded: async function( item ) {
			let pub = await this.publicationStore.restoreDraft(item.item.token, {})
				.then(r => {
					item.item = r.data;
					this.publicationDraftView = { ...{}, ...r.data}
			})
		},
		getChipColor: function(status) {
			switch(status) {
				case 1:
					return "orange";
				case 2:
					return "green";
				default:
					return "";
			}
		},
		isImportable: function(item) {
			let overallStatus = false;

			if( item.wos && item.wosStatusId == 2 && item.scopus && item.scopusStatusId == 2 ) {
				overallStatus = true;
			} else if ( !item.wos && item.scopus && item.scopusStatusId == 2 ) {
				overallStatus = true;
			} else if ( !item.scopus && item.wos && item.wosStatusId == 2 ) {
				overallStatus = true;
			}

			return overallStatus;
		},
		exportImports: function() {

			let query = {
				faculty: this.pickerData.faculty?.id,
				baseUnit: this.pickerData.baseUnit?.id,
				person: this.pickerData.pickedAuthor?.uic
			}

		this.publicationService.downloadImports(query, {format: "xlsx"})
		},
	},
}

</script>;