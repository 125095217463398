import { defineStore } from "pinia";
import PublicationService from "../services/publication-service";

export const publicationStore = defineStore("publicationStore", {
    state: () => ({
        items: [],
        searchQuery: {
			name: null,
            year: 2024,
		},
        publication: {},
        searchQueries: {},
        publicationDrafts: [],
        userPublicationsActiveTab: {},
    }),
    getters: {
        getItems() {
            return this.items
        },
        /*
        async getDefaults() {
            const defaults = await this.get("-/default", {})
            console.log("defaults loaded")
            return defaults;
        }
        */
    },
    actions: {
        clear() {
            this.items = [];
        },
        storeQuery(key, query) {
            this.searchQueries[key] = query
        },
        restoreQuery(key) {
            return this.searchQueries[key]
        },
        hasQuery(key) {
            return (this.searchQueries[key] != undefined)
        },
        async getDefaults(type) {
            let service = new PublicationService();
            const results = await service.get("-/default", {type: type});
            return results.data;
        },
        async get(id, params) {
            let service = new PublicationService();
            const results = await service.get(id, params);
            console.log(results)
            this.publication = results.data;
            return results;
        },
        async fetch(params) {
            let service = new PublicationService();
            const results = await service.fetch(params);
            this.items = results.data.data;
        },
        async create(params, data) {
            let service = new PublicationService();
            try {
                const results = await service.create(params, data)
                this.items = results.data.data;
                return Promise.resolve(results)
            } catch( err ) {
                return Promise.reject(err)
            }
        },
        async update(id, params, data) {
            let service = new PublicationService();
            try {
                const results = await service.put(id, params, data)
                this.items = results.data.data;
                return Promise.resolve(results)
            } catch( err ) {
                return Promise.reject(err)
            }
        },
        async saveDraft(id, params, data) {
            let service = new PublicationService();
            try {
                const results = await service.saveDraft(id, params, data)
                return Promise.resolve(results)
            } catch( err ) {
                return Promise.reject(err)
            }
        },
        async restoreDraft(id, params) {
            let service = new PublicationService();
            const results = await service.restoreDraft(id, params)
            return results;
        },
        async removeDraft(id, params) {
            let service = new PublicationService();
            const results = await service.removeDraft(id, params)
            return results;
        },
        async fetchDrafts(params) {
            let service = new PublicationService();
            const results = await service.fetchDrafts(params);
            this.publicationDrafts = results.data.data;
        },
        async fetchDepartmentDrafts(id, params) {
            let service = new PublicationService();
            const results = await service.fetchDepartmentDrafts(id, params);
            this.publicationDrafts = results.data.data;
        },
        async assignTag(id, tag, params) {
            let service = new PublicationService();
            try {
                const results = await service.assignTag(id, tag, params)
                return Promise.resolve(results)
            } catch( err ) {
                return Promise.reject(err)
            }
        },
        async unassignTag(id, tag, params) {
            let service = new PublicationService();
            try {
                const results = await service.unassignTag(id, tag, params)
                return Promise.resolve(results)
            } catch( err ) {
                return Promise.reject(err)
            }
        },
    }
})