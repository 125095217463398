<template>
    <v-dialog
        :value="dialogOpen"
        scrollable
		:loading="isLoading"
        max-width="600px"
        >
        <v-card tile>
            <v-card-title>
                {{ $t('views.author.name-change') }}
            </v-card-title>
            <v-card-text>
                <v-form v-model="formValid">
                <v-switch v-model="customNameEnabled" @change="optsChanged" :label="$t('authors.customName')"></v-switch>
                <v-select v-model="nameVariantPicker" @change="optsChanged" :disabled="!customNameEnabled" :items="possibleNamesList"></v-select>
                <v-row>
                    <v-col cols="6">
                        <v-text-field required :rules="notEmpty" :disabled="!customNameEnabled" v-model="currentAuthorNames.firstname" :label="$t('authors.name')"></v-text-field>
                        <v-text-field required :rules="notEmpty" :disabled="!customNameEnabled" v-model="currentAuthorNames.initials" :label="$t('authors.initials')"></v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field required :rules="notEmpty" :disabled="!customNameEnabled" v-model="currentAuthorNames.lastname" :label="$t('authors.surname')"></v-text-field>
                        <v-text-field required :rules="notEmpty" :disabled="!customNameEnabled" v-model="currentAuthorNames.indexedAs" :label="$t('authors.indexedAs')"></v-text-field>
                    </v-col>
                </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions class="justify-space-between">
                <v-btn color="error" @click="close()">Zavřít</v-btn>
                <v-btn color="success" @click="save" :disabled="!saveEnabled && !formValid">{{ $t('btn.save') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { useDepartmentStore } from '../../stores/department-store';
import { usePersonStore } from '../../stores/person-store';
import PersonService from "../../services/person-service";

export default {
    name: 'PersonNameModal',
    props: {
        panelTitle: {
            type: String,
            default: "",
        },
        author: Object,
    },
    data: () => ({
        name: null,
        dialogOpen: false,
        personStore: usePersonStore(),
        departmentStore: useDepartmentStore(),
        selectedDepartment: 0,
        isLoading: false,
        departments: [],
        saveEnabled: false,
        altUnit: null,
        altValue: -1,

        formValid: false,

        customNameEnabled: false,

        possibleNamesList: [],

        currentAuthorNames: {
            nameSelect: 0,
            firstname: "",
            lastname: "",
            initials: "",
            indexedAs: "",
        },
        nameVariantPicker: 0,

        foundVariants: {},

        notEmpty: [
            v => !!v || 'Položka je vyžadována',
        ],
    }),
    mounted: function () {
    },
    watch: {

    },
    methods: {
        open: async function (props) {
            console.log("dialog-open");
            this.dialogOpen = true;
            this.isLoading = false;
            this.prepNames();

            this.currentAuthorNames.firstname = this.author.firstname;
            this.currentAuthorNames.lastname = this.author.lastname;
            this.currentAuthorNames.initials = this.author.initials;
            this.currentAuthorNames.indexedAs = this.author.indexedAs;

            if(this.author.customName) {
                this.customNameEnabled = true;
            }

            if (!props) {
                return;
            }
            
            if (props.baseUnit && props.baseUnit.id) {
                this.selectedDepartment = props.baseUnit.id;
            }
        },
        optsChanged: function() {
            this.saveEnabled = true;

            if(this.customNameEnabled) {
                if(this.nameVariantPicker == 0) {
                    this.currentAuthorNames.firstname = this.foundVariants.author.name;
                    this.currentAuthorNames.lastname = this.foundVariants.author.surname;
                    this.currentAuthorNames.initials = this.foundVariants.author.initials;
                    this.currentAuthorNames.indexedAs = this.foundVariants.author.indexedAs;
                }
            } else {
                this.currentAuthorNames.firstname = this.foundVariants.author.name;
                this.currentAuthorNames.lastname = this.foundVariants.author.surname;
                this.currentAuthorNames.initials = this.foundVariants.author.initials;
                this.currentAuthorNames.indexedAs = this.foundVariants.author.indexedAs;
            }
        },
        prepNames: async function() {
            
            let personService = new PersonService();
            personService.fetchNames( this.author.uic )
            .then( r => {
                let foundNames = r.data;
                this.foundVariants = r.data;
                this.generateNamesList(foundNames);
            } )
        },
        generateNamesList: function(data) {

            let original = data.author;
            console.log(data.author)
            this.possibleNamesList = [];

            let tmpItem = {"text": `Výchozí jméno: ${original.displayName}`, "value": 0}
            this.possibleNamesList.push(tmpItem);

            data.variants.forEach( i => {
                let tmpItem = {"text": `${i.displayName}`, "value": i.id }
                this.possibleNamesList.push(tmpItem);
            } )

            tmpItem = {"text": "Nové vlastní jméno", "value": -1}
            this.possibleNamesList.push(tmpItem);

            this.nameVariantPicker = 0;
        },
        close: function (data) {
            this.dialogOpen = false;
            this.$emit("close-dialog", data);
        },
        save: function () {
            console.log("person name change request");

            let data = this.currentAuthorNames;
            data.customName = this.customNameEnabled

            this.close(data);
        },
        clear: function () {
            this.name = "";
        }
    },
}
</script>
