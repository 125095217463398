<template>
	<v-card class="ma-4">
		<v-toolbar flat color="primary" dark>
			<v-toolbar-title>{{ $t('view.publications-list') }}</v-toolbar-title>
			<v-spacer></v-spacer>
			<v-btn color="primary" :to="{name: 'select-new-publication'}" class="mx-2">{{ $t('btn.create-publication') }}</v-btn>

			<template v-slot:extension>
				<v-tabs v-model="publicationStore.userPublicationsActiveTab" align-with-title>
					<v-tabs-slider color="yellow"></v-tabs-slider>
					<v-tab>{{ $t('view.my-publications') }}</v-tab>
					<v-tab>{{ $t('view.publication-drafts') }}</v-tab>
					<v-tab>{{ $t('view.import-publications') }}</v-tab>
				</v-tabs>
			</template>
		</v-toolbar>
		<v-card-text>
			<v-tabs-items v-model="publicationStore.userPublicationsActiveTab">
				<v-tab-item>
					<v-toolbar class="mt-2 mb-2 elevation-0" dense flat>
						<v-select class="mx-2" v-model="query.publicationStateId"
								:items="listsStore.publicationStateList"
								label="Stav publikace"
								required
								></v-select>
						<v-select  class="mx-2" v-model="query.year"
								:items="listsStore.yearList"
								label="Rok publikace"
								required
								></v-select>
						<v-btn @click="search()" class="mx-2">{{ $t('btn.search') }}</v-btn>
						<v-spacer></v-spacer>
						<reload-button-component class="mx-2" v-on:reload="load()" />
						<v-btn @click="downloadCitations()"><v-icon>mdi-arrow-down</v-icon></v-btn>
					</v-toolbar>
		
					<v-data-table :headers="headers" :items="publicationStore.items" :items-per-page="15">
					
					<template v-slot:item.actions="{item}">
						<v-btn icon @click="removePublication(item)"><v-icon class="mr-2">mdi-trash-can</v-icon></v-btn>
						<v-btn v-if="false" icon :to="{name: 'edit-publication', params: {id: item.id}}"><v-icon class="mr-2">mdi-pencil</v-icon></v-btn>
						<v-btn icon :to="{name: 'publication-detail', params: {id: item.id}}"><v-icon class="mr-2">mdi-arrow-right</v-icon></v-btn>
					</template>
					<template v-slot:item.state="{item}">
						<publication-state :stateId="item.publicationStateId" :label="item.publicationState"></publication-state>
					</template>
					<template v-slot:item.type="{item}">
						<v-chip small>{{ item.publicationTypeLabelShort }}</v-chip>
					</template>
					</v-data-table>
				</v-tab-item>
				<v-tab-item>
					<publication-draft-list></publication-draft-list>
				</v-tab-item>
				<v-tab-item>
					<import-publications></import-publications>
				</v-tab-item>
			</v-tabs-items>
		</v-card-text>
		<confirm-dialog ref="confirm"></confirm-dialog>
	</v-card>
</template>

<script> 
import {axiosInst} from '../main'
import { publicationStore } from '../stores/publication-store'
import { authStore } from '../stores/user'
import { listsStore } from '../stores/lists-store'
import PublicationService from '../services/publication-service'
import ReloadButtonComponent from '../components/ReloadButtonComponent'
import PublicationDraftList from '@/components/PublicationDraftList.vue'
import ConfirmDialog from '@/components/dialogs/ConfirmDialog.vue'
import ImportPublications from '@/components/ImportPublications.vue'
import PublicationState from '@/components/parts/PublicationState.vue'
import { useNotificationStore } from '../stores/notification-store'

export default {
	components: { ReloadButtonComponent, PublicationState, PublicationDraftList, ImportPublications, ConfirmDialog },
	name: 'PublicationList',
	props: {
		panelTitle: {
			type: String,
			default: "Přehled publikací"
		}
	},
	data: () => ({
		uic: 0,
		headers: [
			{text: "#", value: 'id'},
			{text: "Název", value: 'name'},
			{text: "Typ", value: 'type'},
			{text: "Stav", value: 'state'},
			{text: "Rok", value: 'yearOfPublication'},
			{text: "Akce", value: 'actions', width: "120px", sortable: false},
		],
		query: {
			year: 2024,
			publicationStateId: -1
		},
		publications: [],
		publicationStore: publicationStore(),
		publicationService: new PublicationService(),
		userStore: authStore(),
		listsStore: listsStore(),
		queryKey: "user-publications",
		linkBackTo: {name: "publications"},
		notificationManager: useNotificationStore(),
	}),
	mounted: async function() {
		await this.listsStore.fetch();

		this.uic = this.$root.getCurrentUser()

		console.log(this.publicationStore.hasQuery(this.queryKey));

		if(this.publicationStore.hasQuery(this.queryKey)) {
			console.log("restoring query");
			this.query = this.publicationStore.restoreQuery(this.queryKey)
		}

		this.$nextTick( () => {
			this.search();
		});
	},
	methods: {
		load: function() {
			this.publicationStore.fetch( {uic: this.userStore.uic} )
		},
		search: function() {
			this.publicationStore.storeQuery(this.queryKey, this.query)
			this.publicationStore.fetch( {uic: this.userStore.uic, year: this.query.year, publicationStateId: this.query.publicationStateId} )
		},
		downloadCitations: function() {
			this.publicationService.downloadFetchCitation( {uic: this.userStore.uic, year: this.query.year, publicationStateId: this.query.publicationStateId} );
		},
		removePublication: function(item) {
			this.$refs.confirm.open(this.$t('view.delete-confirmation'), this.$t('messages.publication-delete-confirmation', [item.name]), {})
			.then( (c) => {
				this.publicationService.remove(item.id, {})
				.then( (r) => {
					this.load();
					this.notificationManager.addSuccess({message: this.$t('messages.publication-deleted', [item.name])})
				})
			} );
		}
	},
}

</script>;