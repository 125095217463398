<template>
	<v-chip
		:close="editable"
		close-icon="mdi-pencil"
		@click:close="fieldClicked"
		>
		{{authorLabel}}
		<PersonNameModal ref="personModal" :author="author" @close-dialog="modalClosed"></PersonNameModal>
	</v-chip>
</template>

<script>
import { useDepartmentStore } from '../../stores/department-store';
import PersonNameModal from '../dialogs/PersonNameModal.vue';

export default {
    name: "AuthorName",
    props: {
        errorMessages: Array,
        author: {},
		editable: Boolean,
    },
    data: function () {
        return {
            timeoutId: null,
            search: "",
            isLoading: false,
			authorLabel: "",
			departmentStore: useDepartmentStore(),
        };
    },
	mounted: async function() {
		this.init();
	},
	watch: {
		author: function(o,n) {
			this.init();
		}
	},
    methods: {
		init: function() {
			if(this.editable) {
				this.authorLabel = this.author.displayName
			}
		},
        clear: function () {
            this.hasValue = false;
        },
		modalOpen: function() {
			let props = {baseUnit: {id: this.author.baseUnitId}}
			this.$refs.personModal.open(props)
		},
		modalClosed: function(response) {
			console.log("b", response);
			if(!response) {return;}
			
			this.author.displayName = response.firstname + " " + response.lastname;
			this.author.firstname = response.firstname;
			this.author.lastname = response.lastname;
			this.author.initials = response.initials;
			this.author.indexedAs = response.indexedAs;
			this.author.customName = response.customName;
			this.init();
			this.updateValue()

			/*
			if(response && response.id && response.id > 0) {
				this.author.baseUnitAbbr = response.abbr;
				this.author.baseUnitId = response.id;
				this.author.baseUnit = response.name;
				this.unitChipLabel = this.author.baseUnitAbbr;
			}
				*/
		},
        updateValue: function () {
            this.$emit("input", this.foundItem);
        },
		fieldClicked: function() {
			console.log(this.valueText)
			if(!this.valueText || this.valueText=="" ) {
				this.modalOpen()
			}
		},
		loadPrimary: async function() {
			/*
			let departments = await this.departmentStore.fetchForAuthor(this.author.uic, {})
			if(departments.length > 0) {
				let primary = departments[0];
				this.author.baseUnitAbbr = primary.abbr;
				this.author.baseUnitId = primary.id;
				this.author.baseUnit = primary.name;
				this.unitChipLabel = this.author.baseUnitAbbr;
				//this.author = author;
			}
			*/
		}
    },
    components: { PersonNameModal }
}
</script>