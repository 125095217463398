<template>
    <v-dialog
        :value="dialogOpen"
        scrollable
        persistent
        max-width="500px"
        >
        <v-card tile>
            <v-toolbar flat dark color="primary">
                <v-toolbar-title>{{ $t('dialogs.publisher-edit') }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="close()"><v-icon>mdi-close</v-icon></v-btn>
            </v-toolbar>
            <v-card-text>
                <v-form v-model="formValid">
                <v-text-field required :rules="notEmpty" label="Název" v-model="formItem.title"></v-text-field>
                <v-text-field label="Název (en)" v-model="formItem.titleEn"></v-text-field>
                <v-text-field label="ISSN" v-model="formItem.issn"></v-text-field>
                <v-text-field label="ISBN" v-model="formItem.isbn"></v-text-field>

                <v-select required label="Vydavatel" v-model="formItem.publisherId" :items="publisherStore.items" item-text="name" item-value="id"></v-select>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-btn color="error" @click="close">{{ $t('btn.close') }}</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="success" :disabled="!formValid" @click="saveForm">{{ $t('btn.save') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import JournalPublisherService from '../../services/journal-publisher-service';
import JournalService from '../../services/journal-service';
import PublicationService from '../../services/publication-service';
import { publishersStore } from '../../stores/journal-publisher-store';
import { listsStore } from '../../stores/lists-store';
import { useNotificationStore } from '../../stores/notification-store';
import { authStore } from '../../stores/user';

export default {
    name: 'JournalEditModal',
    props: {
        panelTitle: {
            type: String,
            default: "Změnit typ publikace",
        },
    },
    data: () => ({
        name: null,
        dialogOpen: false,
        isLoading: false,
        formValid: false,
        userStore: authStore(),
        notificationService: useNotificationStore(),
        publisherStore: publishersStore(),
        journalService: new JournalService(),
        listsStore: listsStore(),
        userdata: {},
        currentUser: -1,
        formItem: {},

        notEmpty: [
            v => !!v || 'Položka je vyžadována',
        ],

        _resolve: null,
        _reject: null,
    }),
    mounted: function() {
        this.dialogOpen = false
    },
    methods: {
        open: function(props) {
            this.dialogOpen = true
            
            this.formItem = {...props.publisher};

            return new Promise((resolve, reject) => {
                this._resolve = resolve
                this._reject = reject
            })
        }, 
        close: function( resp, data ) {
            this.dialogOpen = false
            this.$emit("close-dialog", data)

            if(resp) {
                this._resolve(true)
            } else {
                this._reject(false)
            }
        },
        saveForm: function() {

            let call;
            if(this.formItem.id > 0) {
                call = this.journalService.update( this.formItem.id, this.formItem );
            } else {
                call = this.journalService.create( this.formItem )
            }
            
            call.then( r => {
                this.notificationService.addSuccess({message: "Záznam přidán/upraven."})
                this.close(true, {})
            } )
            .catch( ex => {
                this.notificationService.addError({message: "Záznam s výdajem o publikaci se nepodařilo přidat"})
            } )
        },
        clear: function() {
        }
    }

}
</script>
