<template>
	<v-card class="pa-4 ma-4">
		<v-card-title>
			Granty
		</v-card-title>
		
		<v-toolbar dense class="elevation-0 mt-2">
			<v-row>
				<v-col>
					<v-text-field
						v-model="query.q"
						append-icon="mdi-magnify"
						label="Název/Číslo/Interní číslo"
					></v-text-field>
				</v-col>
				<v-col>
					<v-select
						v-model="query.year"
						:items="years"
						label="Rok"
					></v-select>
				</v-col>
				<v-col>
					<v-select
						v-model="query.publisherId"
						:items="grantPublishers.getForSelect"
						clearable 
						label="Poskytovatel"
					></v-select>
				</v-col>
				<v-col>
					<v-btn small v-on:click="load()">Vyhledat</v-btn>
				</v-col>
				<v-col>
					<v-btn small v-on:click="createItem()" color="primary">Přidat grant</v-btn>
				</v-col>
				<v-col>
					<v-btn small v-on:click="reset()">Reset</v-btn>
				</v-col>
			</v-row>
		</v-toolbar>
		<v-card-text>
			<v-data-table :headers="headers" 
				:items="grantStore.items" 
				:items-per-page="15"
				>
			
			<template v-slot:item.actions="{item}">
				<v-btn icon @click="editItem(item)"><v-icon>mdi-pencil</v-icon></v-btn>
				<v-btn icon :to="{name: 'grant-detail', params: {id: item.id}}"><v-icon>mdi-arrow-right</v-icon></v-btn>
			</template>
			</v-data-table>
		</v-card-text>
		<GrantEditModal ref="grantModal"></GrantEditModal>
	</v-card>
</template>

<script> 
import { axiosInst } from '../main'
import { useGrantStore } from '../stores/grant-store'
import { grantPublisherStore } from '../stores/grant-publisher-store'
import GrantEditModal from './dialogs/GrantEditModal.vue';

export default {
	name: 'GrantSettings',
	components: { GrantEditModal },
	props: {
		panelTitle: {
			type: String,
			default: "Granty"
		}
	},
	data: () => ({
		headers: [
			{text: "#", value: 'value'},
			{text: "Název", value: 'name'},
			{text: 'Interní číslo', value: 'internalNumber'},
			{text: "Číslo", value: 'number'},
			{text: "Od", value: 'yearFrom'},
			{text: "Do", value: 'yearTo'},
			{text: "Akce", value: 'actions', sortable: false},
		],
		items: [],
		search: '',
		grantStore: useGrantStore(),
		grantPublishers: grantPublisherStore(),
		query: useGrantStore().searchQuery,
		years: [ 2019, 2020, 2021, 2022, 2023, 2024, 2025 ],
		rules: [
			value => !!value || 'Required',
			value => (value && value.length >= 3 ) || 'Too short'
		],
	}),
	mounted: function() {
		this.load()
	},
	methods: {
		load: async function() {
			await this.grantStore.fetch(this.query)
		},
		reset: function() {
			this.grantStore.searchQuery.q = ""
			this.grantStore.searchQuery.publisherId = 0
		},
		createItem: function() {
			this.$refs.grantModal.open({grant: {owner: {uic: 0}}})
			.then( e => {
				this.load()
			} )
		},
		editItem: function(item) {
			this.$refs.grantModal.open({grant: item})
			.then( e => {
				this.load()
			} )
		},
	}
}

</script>