import { defineStore } from "pinia";
import AuthService from "../services/auth";

export const authStore = defineStore("authStore", {
    state: () => ({
        uic: Number,
        displayName: String,
        firstname: String,
        lastname: String,
        initials: '--',
        upn: String,
        titleBefore: String,
        titleAfter: String,
        orcId: String,
        scopusId: String,
        loaded: false,
        authorized: false,
        token: String,
        roles: [{role: "ADMINISTRATOR", baseUnitId: -1}],
        wormAuthor: {
            stateId: 0,
        },
    }),
    getters: {
        getAvatar() {

            return "PC";
            if(!this.loaded) {
                 this.login("a", "b")
            }
            return this.firstname.substring(1) + this.lastname.substring(1);
        },
        isAuthorized() {
            return this.authorized;
        },
        hasRole: (state) => {
            return (roles) => {
                return roles.some( (q) => {
                    return state.roles.some( (f) => { return f.role == q });
                });
            }
        }
    },
    actions: {
        async login(user, password) {

            let authService = new AuthService();
            this.authorized = false;

            try {
                let userData = await authService.authenticate(user, password);

                if(userData && userData !== false) {
                    this.$patch({
                        ...userData.data
                    })
                    this.token = userData.data.token
                    this.authorized = (this.token != "");
                    this.loaded = true

                    console.log("saving token to local storage", this.token)
                    localStorage.setItem("token", this.token)

                    return true;
                }
            } catch(err) {
            }
            return false;
        },

        async logout() {
            this.authorized = false;
            this.loaded = false;
            localStorage.setItem("token", "")
        },

        async verifyRefresh() {
            this.loaded = false;
            this.verify();
        },
        
        async verify(force) {
            if(this.loaded) {
                return;
            }
            this.authorized = false;
            let authService = new AuthService();
            
            if(localStorage.getItem("token") != null) {
                this.token = localStorage.getItem("token");
            }

            try {
                var userDetails = await authService.verify(this.token);

                if(userDetails !== false) {
                    this.$patch({
                        ...userDetails
                    })
                    this.authorized = true;
                    this.loaded = true
                }

            } catch( err ) {
                return Promise.reject(err)
            }
        }
    }
})