<template>
    <div>
    <v-card class="pa-4 ma-4" :disabled="cardDisabled">
        <v-progress-linear :active="isLoading" indeterminate />

        <validation-observer ref="observer" v-slot="{invalid}">
            <v-toolbar class="elevation-0">
                <v-btn icon :to="backTo"><v-icon>mdi-arrow-left</v-icon></v-btn>
                <v-toolbar-title>{{$t("panels.publication.new")}}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn color="secondary" class="mx-2" @click="showHelp()" v-if="!showHelpPanel"> {{ $t('btn.help-show') }}</v-btn>
                <v-btn color="secondary" class="mx-2" @click="showHelp()" v-if="showHelpPanel"> {{ $t('btn.help-hide') }}</v-btn>
                <v-btn color="secondary" :disabled="saveDisabled" class="mx-2" @click="changePublicationType()"> {{ $t('btn.change-publication-type') }}</v-btn>
                <v-btn color="orange" :disabled="saveDraftDisabled" class="mx-2" @click="saveDraftPublication()"> {{ $t('btn.save-draft') }}</v-btn>
                <v-btn color="primary" :disabled="saveDisabled" class="mx-2" @click="savePublication()"> {{ $t('btn.save-publication') }}</v-btn>
            </v-toolbar>
            <v-alert type="info" dense v-if="publication.shareMode > 0">
                <v-row align-self-center>
                    <v-col align-self-center>
                        {{ $t('messages.publication-is-in-draft-mode') }}
                    </v-col>
                    <v-col cols="5" align-self-center> 
                        <v-switch dense dark v-model="shareWithAll" inset :label="$t('labels.draft-share-mode', [draftShareMode()])"></v-switch>
                    </v-col>
                </v-row>

            </v-alert>
            <v-row>
                <v-col :cols="showHelpPanel ? 6 : 8">
                    <v-subheader>{{ $t('view.publication-data') }}</v-subheader>
                    <validation-provider vid="name" v-slot="{errors}" rules="required|min:10">
                        <v-text-field v-model="publication.name"
                            :label="$t('publications.name')"
                            :readonly="editDisabled"
                            @change="titleChangeTrigger()"
                            @focus="inputFocused('name')"
                            required
                            :hint="$t('help.hints.publication-name')"
                            :error-messages="errors"
                            ></v-text-field>			
                    </validation-provider>
                    <validation-provider vid="nameEn" name="Název publikace - anglicky" v-slot="{errors}" rules="required|min:10">
                        <v-text-field v-model="publication.nameEn"
                            :label="$t('publications.name-original')"
                            :readonly="editDisabled"
                            @focus="inputFocused('nameEn')"
                            :hint="$t('help.hints.publications.name-original')"
                            :error-messages="errors"
                            required
                            ></v-text-field>	
                    </validation-provider>
                    <validation-provider vid="annotation" name="Anotace v originále" v-slot="{errors}" rules="required|min:62|max:10000">    
                        <v-textarea v-model="publication.annotation"
                        @focus="inputFocused('annotation')"
                        :label="$t('publications.annotation')"
                        :readonly="editDisabled"
                        :error-messages="errors"
                        counter
                        :hint="$t('help.hints.publications.annotation')"
                        ></v-textarea>			
                    </validation-provider>
                    <validation-provider vid="annotationEn" name="Anotace - anglicky" v-slot="{errors}" rules="required|min:62|max:10000">    
                        <v-textarea v-model="publication.annotationEn"
                        @focus="inputFocused('annotationEn')"
                        :readonly="editDisabled"
                        :label="$t('publications.annotation-original')"
                        :error-messages="errors"
                        counter
                        :hint="$t('help.hints.publications.annotation-original')"
                        ></v-textarea>			
                    </validation-provider>
                    <validation-provider vid="keywords" name="Klíčová slova - česky" v-slot="{errors}" rules="required">    
                        <v-text-field v-model="publication.keywords"
                            @focus="inputFocused('keywords')"
                            :readonly="editDisabled"
                            :label="$t('publications.keywords')"
                            :error-messages="errors"
                            :hint="$t('help.hints.publications.keywords')"
                            ></v-text-field>	
                    </validation-provider>
                </v-col>
                
                <v-col cols="4">
                    <v-subheader>{{ $t('views.publication-metadata') }}</v-subheader>
                    <validation-provider vid="yearOfPublication" name="Rok publikace" v-slot="{errors}" rules="required">    
                        <v-select v-model="publication.yearOfPublication"
                            @focus="inputFocused('yearOfPublication')"
                            :readonly="editDisabled"
                            :items="listStore.yearList"
                            :label="$t('publications.year')"
                            :error-messages="errors"
                            required
                            ></v-select>
                    </validation-provider>
                    <validation-provider vid="publicationTypeId" name="Typ publikace" v-slot="{errors}" rules="required">    
                        <v-select v-model="publication.publicationTypeId"
                            :items="listStore.publicationTypeList"
                            @focus="inputFocused('publicationTypeId')"
                            :readonly="editDisabled"
                            item-text="name"
                            item-value="id"
                            :label="$t('publications.type')"
                            :error-messages="errors"
                            readonly
                            required
                            ></v-select>
                    </validation-provider>
                    <validation-provider vid="languageId" name="Jazyk výsledku" v-slot="{errors}" rules="required">    
                        <v-select v-model="publication.languageId"
                            :items="listStore.languageList"
                            @focus="inputFocused('languageId')"
                            :readonly="editDisabled"
                            :label="$t('publications.language')"
                            :hint="$t('help.hints.publications.language')"
                            required
                            :error-messages="errors"
                            ></v-select>
                    </validation-provider>
                    <validation-provider vid="fordId" v-slot="{errors}" rules="required">    
                        <v-autocomplete v-model="publication.fordId"
                            :items="listStore.fordList"
                            @focus="inputFocused('fordId')"
                            :readonly="editDisabled"
                            :label="$t('labels.ford')"
                            :hint="$t('help.hints.ford')"
                            :error-messages="errors"
                            required
                        ></v-autocomplete>
                    </validation-provider>
                    <validation-provider vid="levelOfSecrecyId" v-slot="{errors}" rules="required">    
                        <v-select vid="levelOfSecrecy" v-model="publication.levelOfSecrecyId"
                            :items="listStore.confidentialityList"
                            @focus="inputFocused('levelOfSecrecy')"
                            :readonly="editDisabled"
                            :error-messages="errors"
                            :label="$t('labels.level-of-secrecy')"
                            ></v-select>
                    </validation-provider>
                    <validation-provider vid="doi" v-slot="{errors}" >    
                        <v-text-field v-model="publication.doi"
                            @focus="inputFocused('doi')"
                            :readonly="editDisabled"
                            label="DOI"
                            :hint="$t('help.hints.doi')"
                            :error-messages="errors"
                            required></v-text-field>
                    </validation-provider>
                    <validation-provider vid="web" v-slot="{errors}" rules="required">    
                        <v-text-field v-model="publication.web"
                            @focus="inputFocused('web')"
                            :readonly="editDisabled"
                            :label="$t('labels.web')"
                            :hint="$t('help.hints.web')"
                            :error-messages="errors"
                            required
                            ></v-text-field>
                    </validation-provider>
                    <validation-provider vid="openAccessTypeId" v-slot="{errors}" rules="required">    
                        <v-select vid="openAccessTypeId" v-model="publication.openAccessTypeId"
                            :items="listStore.openAccessList"
                            @focus="inputFocused('openAccessTypeId')"
                            :readonly="editDisabled"
                            :label="$t('labels.open-access-type')"
                            :hint="$t('help.hints.open-access')"
                            :error-messages="errors"
                            required
                            ></v-select>
                    </validation-provider>
                </v-col>
                <v-col cols="2" v-if="showHelpPanel">
                    <v-sheet>
                        <v-subheader>{{ $t('general.help') }}</v-subheader>
                        <v-divider ></v-divider>
                        <div class="mt-3">
                            {{helpText}}
                        </div>
                    </v-sheet>
                </v-col>
            </v-row>
            
            <v-divider class="mt-5"></v-divider>
            <v-card-subtitle>{{ $t('view.publication-specific-data') }}</v-card-subtitle>
            <journal-article-form v-if="publicationType==1" ref="articleJournal" :errors="errors"></journal-article-form>
            <book-form v-if="publicationType==2" ref="subformBook" :errors="errors"></book-form>
            <book-chapter-form v-if="publicationType==3" ref="subformBookChapter" :errors="errors"></book-chapter-form>
            <proceedings-form v-if="publicationType==4" ref="subformProceedings" :errors="errors"></proceedings-form>
            <patent-form v-if="publicationType == 5" ref="subformPatent" :errors="errors"></patent-form>
            <operation-form v-if="publicationType==6" ref="subformOperation" :errors="errors"></operation-form>
            
            <pattern-form v-if="publicationType==7" ref="subformPattern" :errors="errors"></pattern-form>
            <prototype-form v-if="publicationType==8" ref="subformPrototype" :errors="errors"></prototype-form>

            <methodology-form v-if="publicationType==9" :publication="publication" ref="subformMethodology" :errors="errors"></methodology-form>
            <other-form v-if="publicationType==14" :publication="publication" ref="subtypeOther" :errors="errors"></other-form>
            <software-form v-if="publicationType==10" ref="subformSoftware" :errors="errors"></software-form>
            <research-form v-if="publicationType==11" :publication="publication" ref="subformResearch" :errors="errors"></research-form>
            <presentation-form v-if="publicationType==12" :publication="publication" ref="subformPresentation" :errors="errors"></presentation-form>
            <art-form v-if="publicationType==15" ref="subformArt" :publication="publication" :errors="errors"></art-form>
            <conference-form v-if="publicationType==13" ref="conference" :publication="publication" :errors="errors"></conference-form>
            
            <law-result-form v-if="publicationType==16" ref="lawResult" :publication="publication" :errors="errors"></law-result-form>
            <public-database-form v-if="publicationType==17" ref="publicDatabase" :publication="publication" :errors="errors"></public-database-form>
            <exhibition-form v-if="publicationType==18" ref="exhibition" :publication="publication" :errors="errors"></exhibition-form>

        </validation-observer>
	</v-card>
    <v-card class="pa-4 ma-4" :disabled="cardDisabled">
        <publication-authors-manager :authors="authors" :editable="!saveDisabled"></publication-authors-manager>
    </v-card>
    <v-card class="pa-4 ma-4" :disabled="cardDisabled">
        <publication-grant-manager :editable="!saveDisabled" :grants="grants"></publication-grant-manager>
    </v-card>

    <PublicationTypeChangeModal :publication="publication" @close-dialog="publicationTypeChangedModalClosed" ref="publicationTypeChangeModal"></PublicationTypeChangeModal>
    </div>
</template>


<script> 
import JournalArticleForm from './forms/JournalArticleForm.vue'
import BookForm from './forms/BookForm.vue'
import BookChapterForm from './forms/BookChapterForm.vue'
import PersonSearch from './parts/PersonSearch.vue'
//import { required, digits, email, max, min, regex } from 'vee-validate/dist/rules'
import { required, min, max } from 'vee-validate/dist/rules'
import { extend, ValidationObserver } from 'vee-validate'
import { publicationStore } from '../stores/publication-store'
import { listsStore } from '../stores/lists-store'
import { authStore } from '../stores/user'
import DepartmentSearch from './parts/DepartmentSearch.vue'
import PersonPicker from './parts/PersonPicker.vue'
import PublicationAuthorsManager from './parts/PublicationAuthorsManager.vue'
import PublicationGrantManager from './parts/PublicationGrantManager.vue'
import ProceedingsForm from './forms/ProceedingsForm.vue'
import PatentForm from './forms/PatentForm.vue'
import PatternForm from './forms/PatternForm.vue'
import OperationForm from './forms/OperationForm.vue'
import PrototypeForm from './forms/PrototypeForm.vue'
import SoftwareForm from './forms/SoftwareForm.vue'
import MethodologyForm from './forms/MethodologyForm.vue'
import OtherForm from './forms/OtherForm.vue'
import ArtForm from './forms/ArtForm.vue'
import ResearchForm from './forms/ResearchForm.vue'
import PresentationForm from './forms/PresentationForm.vue'
import ConferenceForm from './forms/ConferenceForm.vue'
import ExhibitionForm from './forms/ExhibitionForm.vue'
import LawResultForm from './forms/LawResult.vue'
import PublicDatabaseForm from './forms/PublicDatabase.vue'
import { useNotificationStore } from '../stores/notification-store'
import PublicationTypeChangeModal from './dialogs/PublicationTypeChangeModal.vue'
import { useWormEntryStore } from '../stores/worm-entry-store'
import { useHelpStore } from '../stores/help-store'

extend('required', {
    ...required,
    message: 'Položka {_field_} nesmí být prázdná.',
})

extend('min', {
    ...min,
    message: 'Položka {_field_} je příliš krátká. Musí obsahovat alespoň {length} znaků.',
})

extend('max', {
    ...max,
    message: 'Položka {_field_} je příliš dlouhá. Musí obsahovat maximálně {length} znaků.',
})

export default {
	name: 'PublicationForm',
    props: {
        type: [Number,String],       //publication type
        publicationId: Number,
        uic: Number,
        isNewEntry: Boolean,
        isImporting: Boolean,
        isDraft: Boolean,
        editEntry: [String,Number],
        importEntry: [String,Number],
        draftEntry: [String,Number],
		backTo: { //@fixme - fix proper value pass from router
			type: Object,
			required: false,
			default: () => {return {name: "user-publications"}}
		}
    },
    components: {
    JournalArticleForm,
    BookForm,
    BookChapterForm,
    ValidationObserver,
    PersonSearch,
    DepartmentSearch,
    PersonPicker,
    PublicationAuthorsManager,
    PublicationGrantManager,
    ProceedingsForm,
    PatentForm,
    OperationForm,
    PrototypeForm,
    SoftwareForm,
    MethodologyForm,
    OtherForm,
    ArtForm,
    ResearchForm,
    PresentationForm,
    ConferenceForm,
    LawResultForm,
    PublicDatabaseForm,
    ExhibitionForm,
    PublicationTypeChangeModal,
    PatternForm
},
    data: function() {
		return {
            cardDisabled: false,
            store: publicationStore(),
            listStore: listsStore(),
            userStore: authStore(),
            isLoading: false,
            publicationType: 0,
            publication: {},
            publicationSubtype: {},
            stepperState: 1,
            yearList: {},
            journalIsLoading: false,
            journalFoundEntries: [],
            search: "",
            timeoutId: null,
            publicationTypeList: [],
            languageList: [],
            cepList: [],
            confidentialityList: [],
            openAccessList: [],
            fordList: [],
            rules: {
                titleRules: [
                    v => !!v || 'Toto pole je povinné'
                ]
            },
            grants: [],
            authors: [
            ],
            notificationManager: useNotificationStore(),
            saveDisabled: false,
            saveDraftDisabled: true,
            editDisabled: false,
            errors: [],
            wormStore: useWormEntryStore(),
            draftMode: 0,

            helpStore: useHelpStore(),
            enableHelp: true,
            showHelpPanel: false,
            helpText: "",
        }
    },
    mounted: async function() {
        this.cardDisabled = true;
        //this.publicationIsDraft = false;

        await this.loadLists()

        if(this.isImporting) {
            console.log("importing publication")
            this.requestImport()
        } else {
            if(this.isNewEntry) {
                if( this.isDraft ) {
                    await this.requestDraftRestore()
                } else {
                    await this.loadDefaultPublication()
                }
            } else {
                if(this.editEntry) {
                    await this.loadPublication(this.editEntry)
                }
            }
        }

        this.cardDisabled = false;
    },
    computed: {
        publicationIsInDraftMode: function() {
            return this.draftEntry != null
        },
        shareWithAll: {
            get: function() {
                if(this.publication.shareMode && this.publication.shareMode > 0) {
                    return this.publication.shareMode == 2
                }

                return false;
            },
            set: function(val) {
                this.publication.shareMode = (val ? 2 : 1);
                console.log("switch to", val, this.publication.shareMode)
            }
        }
    },
    methods: {
        loadDefaultPublication: async function() {
            let defaults = await this.store.getDefaults(this.type)
            
            this.publication = defaults
            this.publication.publicationTypeId = this.type*1
            this.publicationType = this.type
//            this.publication.uicGarant = this.userStore.uic
            this.publication.uicCreated = this.userStore.uic
            this.publication.baseUnitId = 16
            this.publication.uicGarant = this.userStore.uic

            if(!this.publication.id) {
                let author = {uic: this.userStore.uic, 
                    displayName: this.userStore.displayName,
                    firstname: this.userStore.firstname,
                    lastname: this.userStore.lastname,
                    type: 1, 
                    priority: 0,
                    baseUnitId: 0,
                    baseUnitName: null,
                    baseUnitAbbr: null,
                }

                this.authors.push(author) 
            }

            console.log(this.publication);
            
            this.$nextTick( () => {this.setSubformDefaults()} )
        },
        loadLists: function() {
            this.listStore.fetch({})
        },
        showHelp: function() {
            this.showHelpPanel = !this.showHelpPanel;
        },  
        loadPublication: async function(id) {
            let publication = await this.store.get(id, {});
            this.publicationType = this.store.publication.publicationTypeId;
            console.log(this.publicationType);
            this.publication = this.store.publication;
            
            this.$nextTick( () => {
                this.authors = this.store.publication.authors;
                this.grants = this.store.publication.grants;
                this.setSubformDefaults()
                this.saveDisabled = (this.publication.publicationStateId != 1 && !this.userStore.hasRole(['ADMINISTRATOR']))
                this.editDisabled = (this.publication.publicationStateId != 1 && !this.userStore.hasRole(['ADMINISTRATOR']))
            } )
        },
        collectPublication: function() {
            var data = this.getSubformByType().getData()
            console.log(data);

            switch(this.publication.publicationTypeId) {
                case 1:
                    this.publication.article = data;
                    break;
                case 2:
                    this.publication.book = data;
                    break;
                case 3:
                    this.publication.bookChapter = data;
                    break;
                case 4:
                    this.publication.proceeding = data;
                    break;
                case 5:
                    this.publication.patent = data;
                    break;
                case 6:
                    this.publication.operation = data;
                    break;
                case 7:
                    this.publication.pattern = data;
                    break;
                case 8:
                    this.publication.prototype = data;
                    break;
                case 9:
                    this.publication.methodology = data;
                    break;
                case 10:
                    this.publication.software = data;
                    break;
                case 11:
                    this.publication.research = data;
                    break;
                case 12:
                    this.publication.presentation = data;
                    break;
                case 13:
                    this.publication.conference = data;
                    break;
                case 14:
                    this.publication.other = data;
                    break;
                case 15:
                    this.publication.art = data;
                    break;
                case 16:
                    this.publication.lawResult = data;
                    break;
                case 17:
                    this.publication.publicDatabase = data;
                    break;
                case 18:
                    this.publication.exhibition = data;
                    break;
            }

            this.publication.grants = this.grants;
            this.publication.authors = this.authors;
        },
        saveDraftPublication: function() {
            this.collectPublication();

            if(this.publication.token != "") {
                this.store.saveDraft( this.publication.token, {}, this.publication)
                    .then( p => {
                        this.notificationManager.addSuccess({message: this.$t('messages.draft-saved')})
                        if(!this.publication || this.publication.shareMode == 0) {
                            this.publication.shareMode = 1
                        }
                    })
                    .catch(m => {
                        this.notificationManager.addError({message: this.$t('messages.draft-save-error')})
                    })
            }
        },
        savePublication: function() {
            this.collectPublication();
            console.log("saving", this.publication)

            try {
                if(!this.isNewEntry && this.editEntry > 0) {
                    this.store.update( this.editEntry, {"validate": 1}, this.publication)
                        .then(p => {
                            console.log("saved", p)
                            this.$emit('form-saved', this.publication)
                            this.draftMode = 0
                            this.notificationManager.addSuccess({message: this.$t('messages.publication-saved')})
                        })
                        .catch(m => {
                            this.$refs.observer.setErrors( m.response.data.errors )
                            this.notificationManager.addError({message: this.$t('messages.form-contains-errors')})
                        })
                } else {
                    if(this.isImporting) {
                        this.publication.sourceId = this.wormStore.publication.importEntryId;
                        console.log(this.publication)
                    }
                    this.store.create({"validate": 1}, this.publication)
                        .then(p => {
                            this.draftMode = 0
                            this.notificationManager.addSuccess({message: this.$t('messages.publication-saved')})
                            this.$emit('form-saved', this.publication)
                            this.$router.push({name: "user-publications"})
                        })
                        .catch(m => {
                            if(m.response.data.errors) {
                                this.notificationManager.addError({message: this.$t('messages.form-contains-errors')})
                                this.$refs.observer.setErrors( m.response.data.errors )
                            } else {
                                this.notificationManager.addError({message: this.$t('messages.error-during-publication-save')})
                            }
                            console.log(m.response.data)
                        })
                    }
            } catch(err) {
                console.log("error happaned", err) 
            }
        },
        setSubformDefaults: function() {

            let subform = this.getSubformByType();

            switch(this.publication.publicationTypeId * 1) {
            case 1:
                    return subform.setData(this.publication.article);
            case 2:
                    return subform.setData(this.publication.book);
            case 3:
                    return subform.setData(this.publication.bookChapter);
            case 4:
                    return subform.setData(this.publication.proceeding);
            case 5:
                    return subform.setData(this.publication.patent);
            case 6:
                    return subform.setData(this.publication.operation);
            case 7:
                    return subform.setData(this.publication.pattern);
            case 8:
                    return subform.setData(this.publication.prototype);
            case 9:
                    return subform.setData(this.publication.methodology);
            case 10:
                    return subform.setData(this.publication.software);
            case 11:
                    return subform.setData(this.publication.research);
            case 12:
                    return subform.setData(this.publication.presentation);
            case 13:
                    return subform.setData(this.publication.conference);
            case 14:
                    return subform.setData(this.publication.other);
            case 15:
                    return subform.setData(this.publication.art);
            case 16:
                    return subform.setData(this.publication.lawResult);
            case 17:
                    return subform.setData(this.publication.publicDatabase);
            case 18:
                    return subform.setData(this.publication.exhibition);
            }
            
        },
        openPersonModal: function() {
            this.$refs.personSearch.open(
                {search: "novák", autoSearch: true}
            );
        },
        personPicked: function(data) {
            if(data) {
                this.publication.uicGarant = data.uic
                this.publication.garantName = data.displayName
            }
        },
        departmentPicked: function(data) {
            console.log("person modal closed", data)
            if(data) {
                console.log(data.id)
                this.publication.baseUnitId = data.id
                this.publication.baseUnitName = data.name
            }
        },
        getSubformByType: function() {
            switch(this.publication.publicationTypeId * 1) {
                case 1:
                    return this.$refs.articleJournal
                case 2:
                    return this.$refs.subformBook
                case 3:
                    return this.$refs.subformBookChapter
                case 4:
                    return this.$refs.subformProceedings
                case 5:
                    return this.$refs.subformPatent
                case 6:
                    return this.$refs.subformOperation
                case 7:
                    return this.$refs.subformPattern
                case 8:
                    return this.$refs.subformPrototype
                case 9:
                    return this.$refs.subformMethodology
                case 10:
                    return this.$refs.subformSoftware
                case 11:
                    return this.$refs.subformResearch
                case 12:
                    return this.$refs.subformPresentation
                case 13:
                    return this.$refs.conference
                case 14:
                    return this.$refs.subtypeOther
                case 15:
                    return this.$refs.subformArt
                case 16:
                    return this.$refs.lawResult
                case 17:
                    return this.$refs.publicDatabase
                case 18:
                    return this.$refs.exhibition
                default:
                    console.log("type not found" + this.publication.publicationTypeId)
            }
        },
        requestImport: function() {
            this.publication = this.wormStore.publication;
            this.publicationType = this.publication.publicationTypeId
            console.log("restoring", this.publication)
            this.publication.uicGarant = this.userStore.uic
            this.publication.baseUnitId = 16

            this.$nextTick( () => {
                this.setSubformDefaults()
                this.authors = this.publication.authors;
                this.titleChangeTrigger()
            })
        },
        requestDraftRestore: async function() {
            let pub = await this.store.restoreDraft(this.draftEntry, {})
            this.publication = pub.data
            this.publicationType = this.publication.publicationTypeId;
            this.publication.uicGarant = this.userStore.uic;
            this.publication.baseUnitId = 16;
            
            if( pub.shareMode ) {
                this.draftMode = pub.shareMode;
            } else {
                this.draftMode = 1
            }

            this.$nextTick( () => {
                this.setSubformDefaults()
                this.authors = this.publication.authors

                if(this.publication.grants && this.publication.grants.length > 0) {
                    let fixedGrants = this.publication.grants.filter( q => {
                        if(q && q != null) {
                            return q;
                        }
                    } )
                    this.grants = fixedGrants
                }

                this.titleChangeTrigger()
            })
        },
        changePublicationType: function() {
            this.$refs.publicationTypeChangeModal.open()
        },
        publicationTypeChangedModalClosed: function(data) {
            console.log("mod close", data)
            if(data) {
                if(this.editEntry > 0) {  
                    this.loadPublication(this.editEntry)
                } else {
                    this.publicationType = this.publication.publicationTypeId
                }
            }
        },
        inputFocused: function(name, event) {
            console.log("input focused", name)

            let info = this.helpStore.getHelp(name)

            if(info != "") {
                this.helpText = info.text;
            } else {
                this.helpText = "Tato položka nemá nápovědu";
            }
        },
        titleChangeTrigger: function() {
            if(this.publication.id > 0 && this.publication.name != "" && this.publication.token != "") {
                this.saveDraftDisabled = true;
            } else {
                this.saveDraftDisabled = false;
            }
        },
        draftShareMode: function() {
            if(this.publication.shareMode && this.publication.shareMode == 2) {
                return this.$t('message.draft-shared-with-other-authors')
            } else {
                return this.$t('messages.draft-shared-only-with-you')
            }
        }
    }

}
</script>