var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"3"}},[_c('author-picker',{model:{value:(_vm.pickerData),callback:function ($$v) {_vm.pickerData=$$v},expression:"pickerData"}})],1),_c('v-divider',{attrs:{"vertical":""}}),_c('v-col',[(_vm.toolbarTitle != '')?_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',{staticClass:"h3"},[_vm._v(_vm._s(_vm.toolbarTitle))]),_c('v-spacer'),_c('v-menu',{staticClass:"ml-2",attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({},'v-btn',attrs,false),on),[_vm._v(" Exporty a sestavy ")])]}}],null,false,272243548)},[_c('v-list',[_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.exportImports()}}},[_vm._v(" Exportovat ")])],1)],1)],1):_vm._e(),(!_vm.hasData)?_c('div',[_c('v-alert',{attrs:{"type":"info","dense":"","outlined":""}},[_vm._v("Pro vybrané zobrazení nelze zobrazit data, vyberte podřízenou položku.")])],1):_vm._e(),(_vm.hasData)?_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.publicationStore.items,"items-per-page":15},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.title)+" "),(item.earlyAccess)?_c('v-chip',{attrs:{"x-small":"","color":"green","dark":""}},[_c('v-icon',[_vm._v("mdi-clock-outline")]),_vm._v(" Early access")],1):_vm._e(),_c('v-chip',{attrs:{"x-small":"","color":"gray"}},[_vm._v(_vm._s(item.importDate))])]}},{key:"item.imported",fn:function(ref){
var item = ref.item;
return [(item.stateId==3 )?_c('v-icon',[_vm._v("mdi-check-circle")]):_vm._e(),(item.stateId==2 )?_c('v-icon',[_vm._v("mdi-progress-helper")]):_vm._e()]}},{key:"item.source",fn:function(ref){
var item = ref.item;
return [_c('div',[(item.wos)?_c('v-chip',{attrs:{"small":"","color":_vm.getChipColor(item.wosStatusId),"dark":""}},[_c('v-icon',[_vm._v("mdi-alpha-w")]),(item.wosStatusId==1)?_c('v-progress-circular',{staticClass:"ml-1",attrs:{"indeterminate":"","size":"15","width":"1"}}):_vm._e(),(item.wosStatusId==2)?_c('v-icon',{staticClass:"ml-1",attrs:{"small":""}},[_vm._v("mdi-check")]):_vm._e()],1):_vm._e(),(item.scopus)?_c('v-chip',{staticClass:"ml-2",attrs:{"color":_vm.getChipColor(item.scopusStatusId),"small":"","dark":""}},[_c('v-icon',[_vm._v("mdi-alpha-s")]),(item.scopusStatusId==1)?_c('v-progress-circular',{attrs:{"indeterminate":"","size":"15","width":"1"}}):_vm._e(),(item.scopusStatusId==2)?_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-check")]):_vm._e()],1):_vm._e()],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.stateId!=3 && !_vm.isImportable(item))?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e(),(item.stateId!=3 && _vm.isImportable(item))?_c('v-btn',{attrs:{"target":"_blank","icon":"","to":{name: 'import-publication', params: {id: item.id, backTo: {name: 'publications'}}}}},[_c('v-icon',[_vm._v("mdi-import")])],1):_vm._e(),(item.stateId==3 && item.publicationId > 0)?_c('v-btn',{attrs:{"target":"_blank","icon":"","to":{name: 'publication-detail', params: {id: item.publicationId}}}},[_c('v-icon',[_vm._v("mdi-book-open-blank-variant")])],1):_vm._e()]}}],null,false,4165829350)}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }