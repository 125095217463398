<template>
	<v-row>
		<v-col cols="12">
			<v-text-field
			v-model="labelValue"
			prepend-icon="mdi-database-search"
			:label="label"
			:error-messages="errorMessages"
			readonly
			:loading="isLoading"
			clearable
			:required="required"
			@click:clear="clear()"
			@click="fieldClicked"
			:rules="rules"
			>
			<template v-slot:append-outer>
				<v-btn depressed @click="modalOpen">{{ $t('btn.search-person') }}<v-icon right>mdi-magnify</v-icon></v-btn>
			</template>

			</v-text-field>
		</v-col>
		
		<person-search-modal ref="personSearch" @close-dialog="modalClosed"></person-search-modal>
	</v-row>
</template>

<script>
import { required } from 'vee-validate/dist/rules';
import PersonSearchModal from '../dialogs/PersonSearchModal.vue';
import { usePersonStore } from '../../stores/person-store';

export default {
    name: "PersonInput",
    props: {
        errorMessages: Array,
		label: String,
		value: [String, Number],
		rules: {
			type: Array,
			default: []
		},
		required: {
			default: false,
			type: Boolean
		},
    },
    data: function () {
        return {
			labelValue: "",
            timeoutId: null,
            search: "",
            isLoading: false,
            hasValue: false,
			foundItem: {},
			authorStore: usePersonStore(),
        };
    },
	watch: {
		value: function(nV) {
			console.log("watcher - value updated", nV)
			//this.labelValue = nV;
			this.loadPerson(nV);
		}

	},
	mounted: function() {
		this.loadPerson()
	},
    methods: {
        clear: function () {
            this.hasValue = false;
			this.foundItem = null;
			this.labelValue = "";
			this.updateValue(0);
        },
		loadPerson: function(uic) {
			console.log("person input load uic", uic)
			if(uic > 0) {
				this.isLoading = true
				this.authorStore.getPerson(uic)
				.then(r => {
					this.labelValue = r.data.displayName;
					this.isLoading = false
				})
				.finally( () => {
					this.isLoading = false
				})
			} else {
				this.labelValue = "";
			}
		},
		modalOpen: function() {
			this.$refs.personSearch.open()
		},
		modalClosed: function(response) {
			this.foundItem = response;
			this.labelValue = this.foundItem.displayName
			this.updateValue(this.foundItem.uic)
		},
        updateValue: function (val) {
            this.$emit("input", val);
        },
		fieldClicked: function() {
			console.log(this.valueText)
			if(!this.valueText || this.valueText=="" ) {
				this.modalOpen()
			}
		}
    },
    components: { PersonSearchModal }
}
</script>