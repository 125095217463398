// https://github.com/SortableJS/Vue.Draggable?ref=madewithvuejs.com
import Vue from 'vue'
import App from './App.vue'
//import PublicationList from './components/PublicationList.vue'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import pinia from './plugins/pinia'
import router from './router'
import emitter from './plugins/mitt'
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import i18n from './i18n'
import { useApplicationStore } from './stores/application'
import VueApexCharts from 'vue-apexcharts'

Vue.config.productionTip = false

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)

export const axiosInst = axios.create({
	//baseURL: "http://localhost:8783/api/v1/",
	//baseURL: "http://localhost:8087/api/v1/",
	//baseURL: "http://api-test.oikt.czu.cz:7778/api/v1/",
	baseURL: "/api/v1/",
	headers: {
		"Content-Type" : "application/json"
	}
});

new Vue({
    vuetify,
    pinia,
    router,
	emitter,

    data: {
		currentUIC: 123,
		effectiveUIC: 123,
		applicationLoading: false,
	},

    emits: ['application-loading'],

    created: function() {
		axiosInst.interceptors.request.use( request => {
			console.log("axios loading");
			this.applicationLoading = true
			useApplicationStore().loading = true
			this.$emit("applicationLoading", true)

			const token = localStorage.getItem("token");
			if(token != "") {
				request.headers["Authorization"] = `Bearer ${token}`
			}

			return request;
		}, error => {
			console.log(error)
		});

		axiosInst.interceptors.response.use( response => {
			
			this.applicationLoading = false
			console.log("axios loaded");
			setTimeout( () => {
				useApplicationStore().loading = false
				this.$emit("applicationLoading", false)
			}, 1000)
			return response;
		}, error => {
//			console.log("axios err", error)
			this.applicationLoading = false
			console.log("axios - error detected", error.response.status)
			setTimeout( () => {
				useApplicationStore().loading = false
				this.$emit("applicationLoading", false)
			}, 1000)
			return Promise.reject(error)
		})
		
	},

    methods: {
		getCurrentUser: function() {
			return this.currentUIC
		},
		getEffectiveUser: function() {
			return this.effectiveUIC
		}
	},

    i18n,
    render: h => h(App)
})
.$mount('#app')

