import { axiosInst } from "../main";
import BaseService from "./base-service";

export default class PublicationService extends BaseService {
    
    async fetch( params )  {
        const response = await axiosInst.get('/publications', {
            params: params
        })
        return response
    }

    async query( query, params )  {
        const response = await axiosInst.post('/publications/query', query, {
            params: params
        })
        return response
    }

    async get( id, params )  {
        let path = (id) => `/publications/${id}`
        const response = await axiosInst.get( path(id), {
            params: params
        })
        return response
    }

    async create( params, data )  {
        let path = `/publications`
        try {
            const response = await axiosInst.post( path, data, {
                params: params
            })
            return response;
        } catch(err) {
            return Promise.reject(err)
        }
    }

    async put( id, params, data )  {
        let path = (id) => `/publications/${id}`
        try {
            const response = await axiosInst.put( path(id), data, {
                params: params
            })
            return response;
        } catch(err) {
            return Promise.reject(err)
        }
    }

    async changeType( id, params, data )  {
        let path = (id) => `/publications/${id}/actions/change-type`
        try {
            const response = await axiosInst.post( path(id), data, {
                params: params
            })
            return response;
        } catch(err) {
            return Promise.reject(err)
        }
    }

    async changeState( id, params, data, loadingCallback)  {
        let path = (id) => `/publications/${id}/actions/change-state`
        try {
            const response = await axiosInst.post( path(id), data, {
                params: params
            })
            this.handleCallback(loadingCallback)
            return response;
        } catch(err) {
            return Promise.reject(err)
        }
    }

    async remove( id, params )  {
        const response = await axiosInst.delete( `/publications/${id}`, {
            params: params
        })
        return response
    }

    async saveDraft( id, params, data )  {
        let path = (id) => `/authors/-/publication-drafts/${id}`
        try {
            const response = await axiosInst.put( path(id), data, {
                params: params
            })
            return response;
        } catch(err) {
            return Promise.reject(err)
        }
    }

    async restoreDraft( id, params )  {
        let path = (id) => `/authors/-/publication-drafts/${id}`
        const response = await axiosInst.get( path(id), {
            params: params
        })
        return response
    }

    async removeDraft( id, params )  {
        let path = (id) => `/authors/-/publication-drafts/${id}`
        const response = await axiosInst.delete( path(id), {
            params: params
        })
        return response
    }

    async fetchDrafts( id, params )  {
        let path = () => `/authors/-/publication-drafts`
        const response = await axiosInst.get( path(), {
            params: params
        })
        return response
    }

    async fetchDepartmentDrafts( id, params )  {
        let path = () => `/departments/${id}/publication-drafts`
        const response = await axiosInst.get( path(), {
            params: params
        })
        return response
    }

    async assignTag( id, tag, params)  {
        let path = (id, tag) => `/publications/${id}/tags/${tag}`
        try {
            const response = await axiosInst.put( path(id, tag), {}, {
                params: params
            })
            return response;
        } catch(err) {
            return Promise.reject(err)
        }
    }

    async unassignTag( id, tag, params)  {
        let path = (id, tag) => `/publications/${id}/tags/${tag}`
        try {
            const response = await axiosInst.delete( path(id, tag), {}, {
                params: params
            })
            return response;
        } catch(err) {
            return Promise.reject(err)
        }
    }

    async fetchTags( id, params)  {
        let path = (id) => `/publications/${id}/tags`
        try {
            const response = await axiosInst.get( path(id), {}, {
                params: params
            })
            return response;
        } catch(err) {
            return Promise.reject(err)
        }
    }

    async fetchMetadata( id, params)  {
        let path = (id) => `/publications/${id}/metadata`
        try {
            const response = await axiosInst.get( path(id), {}, {
                params: params
            })
            return response;
        } catch(err) {
            return Promise.reject(err)
        }
    }

    async fetchExpenses( id, params)  {
        let path = (id) => `/publications/${id}/expenses`
        try {
            const response = await axiosInst.get( path(id), {}, {
                params: params
            })
            return response;
        } catch(err) {
            return Promise.reject(err)
        }
    }

    async createExpense( id, data, params)  {
        let path = (id) => `/publications/${id}/expenses`
        try {
            const response = await axiosInst.post( path(id), data, {
                params: params
            })
            return response;
        } catch(err) {
            return Promise.reject(err)
        }
    }

    async updateExpense( id, eid, data, params)  {
        let path = (id, eid) => `/publications/${id}/expenses/${eid}`
        try {
            const response = await axiosInst.put( path(id, eid), data, {
                params: params
            })
            return response;
        } catch(err) {
            return Promise.reject(err)
        }
    }

    async downloadCitation(id, format) {
        let path = (id) => `/publications/${id}/citations`
        try {
            let params = {format: format}
            const response = await axiosInst.get( path(id), {
                responseType: 'blob',
                params: params
            }).then( (r) => {
                const filename = `pub_citation_${id}.${format}`
                const blob = new Blob( [r.data] );
                const href = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href=href;
                link.setAttribute('download', filename)
                document.body.appendChild(link);
                link.click();

                document.body.removeChild(link);
                URL.revokeObjectURL(href)
            })
            return response;
        } catch(err) {
            return Promise.reject(err)
        }
    }

    async downloadFetchCitation(params) {
        try {
            const response = await axiosInst.get( `/publications/-/citations`, {
                responseType: 'blob',
                params: params
            }).then( (r) => {
                const filename = `pub_citation_list.docx`
                const blob = new Blob( [r.data] );
                const href = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href=href;
                link.setAttribute('download', filename)
                document.body.appendChild(link);
                link.click();

                document.body.removeChild(link);
                URL.revokeObjectURL(href)
            })
            return response;
        } catch(err) {
            return Promise.reject(err)
        }
    }

    async downloadPublicationQueryCitations( query, params, loadingCallback )  {
        const response = await axiosInst.post(`/publications/-/citations`, query, {
            responseType: 'blob',
            params: params
        }).then( (r) => {
            const href = URL.createObjectURL(r.data);
            const link = document.createElement('a');
            link.href= href;
            link.setAttribute('download', `cv-file-citations.${params.format}`)
            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            URL.revokeObjectURL(href)
        } )
        
        this.handleCallback(loadingCallback)
        return response
    }

    async downloadPublicationExport( query, params, loadingCallback )  {
        const response = await axiosInst.post(`/publications/-/export`, query, {
            responseType: 'blob',
            params: params
        }).then( (r) => {
            const href = URL.createObjectURL(r.data);
            const link = document.createElement('a');
            link.href= href;
            link.setAttribute('download', `cv-file-export.xlsx`)
            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            URL.revokeObjectURL(href)
        } )
        
        this.handleCallback(loadingCallback)
        return response
    }

    async downloadPublicationByAuthorsExport( query, params, loadingCallback )  {
        const response = await axiosInst.post(`/publications/-/exports/author-counts`, query, {
            responseType: 'blob',
            params: params
        }).then( (r) => {
            const href = URL.createObjectURL(r.data);
            const link = document.createElement('a');
            link.href= href;
            link.setAttribute('download', `cv-file-authors-export.xlsx`)
            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            URL.revokeObjectURL(href)
        } )
        
        this.handleCallback(loadingCallback)
        return response
    }

    async downloadPublicationByProjectsExport( query, params, loadingCallback )  {
        const response = await axiosInst.post(`/publications/-/exports/by-projects`, query, {
            responseType: 'blob',
            params: params
        }).then( (r) => {
            const href = URL.createObjectURL(r.data);
            const link = document.createElement('a');
            link.href= href;
            link.setAttribute('download', `cv-file-projects-export.xlsx`)
            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            URL.revokeObjectURL(href)
        } )
        
        this.handleCallback(loadingCallback)
        return response
    }

    async downloadDrafts( query, params, loadingCallback )  {
        const response = await axiosInst.post(`/publication-drafts/-/exports`, query, {
            responseType: 'blob',
            params: params
        }).then( (r) => {
            const href = URL.createObjectURL(r.data);
            const link = document.createElement('a');
            link.href= href;
            link.setAttribute('download', `cv-file-drafts-export.xlsx`)
            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            URL.revokeObjectURL(href)
        } )
        
        this.handleCallback(loadingCallback)
        return response
    }

    async downloadImports( query, params, loadingCallback )  {
        const response = await axiosInst.post(`/worm/entries/-/exports`, query, {
            responseType: 'blob',
            params: params
        }).then( (r) => {
            const href = URL.createObjectURL(r.data);
            const link = document.createElement('a');
            link.href= href;
            link.setAttribute('download', `cv-file-import-export.xlsx`)
            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            URL.revokeObjectURL(href)
        } )
        
        this.handleCallback(loadingCallback)
        return response
    }

    async fetchXMLForPublication( id, query, params )  {
        const response = await axiosInst.post(`/publications/${id}/exports/riv`, query, {
            responseType: 'blob',
            params: params
        })

        return response
    }

    async downloadXMLForPublication( id, query, params, loadingCallback )  {
        this.fetchXMLForPublication(id, query, params )
        .then( (r) => {
            const href = URL.createObjectURL(r.data);
            const link = document.createElement('a');
            link.href= href;
            
            let filename = "cv-file.vav";
            if(r.headers['x-cv-filename']) {
                filename = r.headers['x-cv-filename'];
            }
            link.setAttribute('download', `${filename}`)
            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            URL.revokeObjectURL(href)
        } )
        
        this.handleCallback(loadingCallback)
        return response
    }

    async downloadXMLForPublications( query, params, loadingCallback )  {
        this.fetchXMLForPublication("-", query )
        .then( (r) => {
            const href = URL.createObjectURL(r.data);
            const link = document.createElement('a');
            link.href= href;
            
            let filename = "cv-file.vav";
            if(r.headers['x-cv-filename']) {
                filename = r.headers['x-cv-filename'];
            }
            link.setAttribute('download', `${filename}`)
            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            URL.revokeObjectURL(href)
        } )
        
        this.handleCallback(loadingCallback)
        return response
    }

    async fetchLogs( id, params)  {
        let path = (id) => `/publications/${id}/logs`
        try {
            const response = await axiosInst.get( path(id), {}, {
                params: params
            })
            return response;
        } catch(err) {
            return Promise.reject(err)
        }
    }
}