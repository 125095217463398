import { defineStore } from "pinia";
import PublicationService from "../services/publication-service";

export const useApprovalStore = defineStore("approvalStore", {
    state: () => ({
        items: [],
        searchQuery: {
			name: null,
            uic: 0,
            uics: [],
            year: 2024,
            years: [2024],
            baseUnitId: 0,
            baseUnitIds: [],
            facultyId: null,
            publicationStateId: 1,
            publicationStateIds: [],
            excludeTags: [],
            includeTags: [],
            outputGrouping: 0,
            publicationTypeId: null,
            publicationTypeIds: [],
		},
        publication: {},
        pickedBaseUnit: {},
        preferences: {
            selectMultipleItems: true,
        }
    }),
    getters: {
        getItems() {
            return this.items
        },
        /*
        async getDefaults() {
            const defaults = await this.get("-/default", {})
            console.log("defaults loaded")
            return defaults;
        }
        */
    },
    actions: {
        clear() {
            this.items = [];
        },
        async get(id, params) {
            let service = new PublicationService();
            const results = await service.get(id, params);
            console.log(results)
            this.publication = results.data;
        },
        async query(query, params) {
            let service = new PublicationService();
            const results = await service.query(query, params)
            this.items = results.data.data;
        },
        async fetch(params) {
            let service = new PublicationService();
            const results = await service.fetch(params);
            this.items = results.data.data;
        },
        async changeState(id, newState, note, params, loadingCallBack) {
            let service = new PublicationService();

            let payload = {
                id: id,
                publicationStateId: newState,
                note: note
            }

            await service.changeState(id, params, payload, loadingCallBack);
        },
        async create(params, data) {
            let service = new PublicationService();
            try {
                const results = await service.create(params, data)
                this.items = results.data.data;
                return Promise.resolve(results)
            } catch( err ) {
                return Promise.reject(err)
            }
        },
        async update(id, params, data) {
            let service = new PublicationService();
            try {
                const results = await service.put(id, params, data)
                this.items = results.data.data;
                return Promise.resolve(results)
            } catch( err ) {
                return Promise.reject(err)
            }
        }
    }
})