<template>
	<v-card flat class="ma-4">
		<v-card-title>
			Vydavatelé časopisů
		</v-card-title>
		<v-toolbar flat>
			<v-btn @click="createItem()" v-if="userStore.hasRole(['ADMINISTRATOR'])" class="mx-2 primary">Přidat vydavatele</v-btn>
			<reload-button-component v-on:reload="load()" />
			<v-spacer></v-spacer>
			<v-text-field
				v-model="search"
				append-icon="mdi-magnify"
				label="Search"
				single-line
				hide-details
			></v-text-field>
		</v-toolbar>
		<v-card-text>
			<v-data-table :headers="headers" 
				:items="publishersStore.items" 
				:items-per-page="15"
				:search="search">
			
			<template v-slot:item.actions="{item}">
				<v-btn icon v-if="userStore.hasRole(['ADMINISTRATOR'])" @click="editItem(item)"><v-icon class="mr-2">mdi-pencil</v-icon></v-btn>
				<v-btn icon v-bind:disabled="true"><v-icon class="mr-2">mdi-delete</v-icon></v-btn>
			</template>
			</v-data-table>
		</v-card-text>
		<PublisherEditModal ref="publisherModal"></PublisherEditModal>
	</v-card>
</template>

<script> 
import { axiosInst } from '../main'
import ReloadButtonComponent from '@/components/ReloadButtonComponent.vue'
import { publishersStore } from '../stores/journal-publisher-store'
import { authStore } from '../stores/user';
import PublisherEditModal from './dialogs/PublisherEditModal.vue';
import JournalPublisherService from '../services/journal-publisher-service';

export default {
  components: { ReloadButtonComponent, PublisherEditModal },
	name: 'GrantPublisherSettings',
	props: {
		panelTitle: String,
	},
	data: () => ({
		headers: [
			{text: "#", value: 'id'},
			{text: "Název", value: 'name'},
			{text: "Akce", value: 'actions', sortable: false},
		],
		items: [],
		search: '',
		userStore: authStore(),
		dialogOpened: false,
		publishersStore: publishersStore(),
		publisherService: new JournalPublisherService()
	}),
	mounted: function() {
		this.load()
	},
	methods: {
		load: function() {
			this.publishersStore.fetch({})
		},
		createItem: function() {
			this.$refs.publisherModal.open({publisher: null})
			.then( e => {
				this.load()
			} )
		},
		editItem: function(item) {
			this.$refs.publisherModal.open({publisher: item})
			.then( e => {
				this.load()
			} )
		},
		openDialog: function() {
			this.dialogOpened = true
		},
	}
}

</script>