<template>
	<div>
	<v-card class="pa-4 ma-4">
		<v-card-title>Detail útvaru</v-card-title>
		<v-toolbar dense flat>
			<DepartmentPicker @input="unitPicked" :value="baseUnit.id" :value-text="baseUnit.name"  class="mx-2"></DepartmentPicker>
			<v-select  class="mx-2" v-model="viewYear"
					:items="listsStore.yearList"
					label="Rok publikace"
					required
					></v-select>
			<v-btn @click="loadDepartment()" class="mx-2">Zobrazit</v-btn>
			<v-spacer></v-spacer>
			<reload-button-component class="mx-2" v-on:reload="load()" />
		</v-toolbar>
		<v-card-text v-if="unitLoaded">
			<v-divider></v-divider>
			<v-row class="mt-5">
				<v-col cols="6">
					<v-text-field label="Název útvaru" v-model="baseUnit.name" readonly></v-text-field>
				</v-col>
				<v-col>
					<v-text-field label="Zkratka útvaru" v-model="baseUnit.abbr" readonly></v-text-field>
				</v-col>
				<v-col>
					<v-text-field label="Kód útvaru" v-model="baseUnit.code" readonly></v-text-field>
				</v-col>
				<v-col>
					<v-menu offset-y>
						<template v-slot:activator="{ on, attrs }">
							<v-btn v-bind="attrs" v-on="on" primary>Export</v-btn>
						</template>
						<v-list>
							<v-list-item link @click="download('text')">
								<v-list-item-title>Uložit jako CSV</v-list-item-title>
							</v-list-item>
							<v-list-item link @click="download('xlsx')">
								<v-list-item-title>Uložit jako Excel (XLSX)</v-list-item-title>
							</v-list-item>
							<v-list-item link @click="exportCitations()">
								<v-list-item-title>Exportovat citace (Word)</v-list-item-title>
							</v-list-item>
							<v-list-item link @click="exportPublications()">
								<v-list-item-title>Exportovat publikace (XLSX)</v-list-item-title>
							</v-list-item>
						</v-list>
					</v-menu>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="8">
					<v-card-title>Publikace autorů v roce {{ viewYear }}</v-card-title>

					<v-data-table :headers="authorHeaders" :items="authorStats" 
						:items-per-page="50"
						show-expand
						:expanded.sync="expanded"
						item-key="author.uic"
						>
						<template v-slot:item.author="{item}">
							{{ item.author.displayName }}
						</template>
						<template v-slot:item.approved="{item}">
							<v-chip color="green">{{ item.total.noOfApproved }}</v-chip>
						</template>
						<template v-slot:item.rejected="{item}">
							<v-chip color="red" v-if="item.total.noOfRejected > 0">{{ item.total.noOfRejected }}</v-chip>
						</template>
						<template v-slot:item.pending="{item}">
							<v-chip color="orange" v-if="item.total.noOfPending > 0">{{ item.total.noOfPending }}</v-chip>
						</template>
						<template v-slot:item.totalCount="{item}">
							{{ item.total.totalCount }}
						</template>
						<template v-slot:item.points="{item}">
							<span v-if="item.total.pointsPending > 0"><v-chip color="orange" small >{{ item.total.pointsPending.toFixed(2) }}</v-chip> + </span>
							<v-chip color="green" small>{{ item.total.points.toFixed(2) }}</v-chip>
						</template>
						<template v-slot:expanded-item="{headers, item}">
							<td :colspan="headers.length" elevation="0" flat>
								<v-sheet  class="pt-2 pb-2" flat>
									<v-row v-for="pubStat in item.publications" :key="pubStat.publicationTypeId">
										<v-col cols="10">{{ pubStat.publicationType }}</v-col>
										<v-col> <v-chip small>{{ pubStat.totalCount }}</v-chip></v-col>
										<v-col> <v-chip small>{{ pubStat.points.toFixed(2) }}</v-chip></v-col>
									</v-row>
								</v-sheet>
							</td>
						</template>
					</v-data-table>

				</v-col>
				<v-col>
					<v-card-title>Typy publikací v roce {{ viewYear }}</v-card-title>
					<v-row v-for="pubStat in pubStats" :key="pubStat.publicationTypeId">
						<v-col cols="8">{{ pubStat.publicationType }}</v-col>
						<v-col> <v-chip>{{ pubStat.totalCount }}</v-chip></v-col>
						<v-col> <v-chip small>{{ pubStat.points.toFixed(2) }} (body)</v-chip></v-col>
					</v-row>
				</v-col>
			</v-row>
		</v-card-text>
	</v-card>
	</div>
</template>

<script> 
import {axiosInst} from '../main'
import ReloadButtonComponent from '@/components/ReloadButtonComponent.vue'
import { publicationStore } from '../stores/publication-store'
import { authStore } from '../stores/user'
import DepartmentPicker from './parts/DepartmentPicker.vue'
import { listsStore } from '../stores/lists-store'
import { useDepartmentStore } from '../stores/department-store'
import PublicationService from '../services/publication-service'

export default {
	components: { ReloadButtonComponent, DepartmentPicker },
	name: 'DepartmentDetail',
	props: {
		panelTitle: {
			type: String,
			default: "Prohlížení záznamů"
		}
	},
	data: () => ({
		uic: 0,
		authorHeaders: [
			{text: "#", value: 'author.uic'},
			{text: "Autor", value: 'author.displayName'},
			{text: "Připraveno", value: 'pending'},
			{text: "Odmítnuto", value: 'rejected'},
			{text: "Schváleno", value: 'approved'},
			{text: "Celkem", value: 'total.totalCount'},
			{text: "Body", value: 'points'},
		],
		query: publicationStore().searchQuery,
		expanded: [],
		listsStore: listsStore(),
		userStore: authStore(),
		departmentStore: useDepartmentStore(),
		publicationService: new PublicationService(),
		pickedPerson: {},
		baseUnit: {},
		department: {},
		unitLoaded: false,
		authorStats: [],
		pubStats: [],
		viewYear: 2024,
	}),
	mounted: async function() {
		await this.listsStore.fetch();

		this.uic = this.$root.getCurrentUser()
		console.log(this.uic)

		//this.unitPicked = {id:16,"name":"Katedra informačních technologií","abbr":"KIT","primary":false,"valid":false};
		//await this.loadDepartment()
		
	},
	methods: {
		load: function() {
			this.publicationStore.fetch( {uic: this.userStore.uic} )
		},
		search: function() {
			this.publicationStore.clear();
			this.publicationStore.fetch( this.query );
		},
		loadDepartment: async function() {
			this.unitLoaded = true;
			this.baseUnit = await this.departmentStore.get( this.baseUnit.id, {}, null )
			this.pubStats = await this.departmentStore.fetchPubStats( this.baseUnit.id, {year: this.viewYear} );
			this.authorStats = await this.departmentStore.fetchAuthorStats( this.baseUnit.id, {year: this.viewYear} );
		},
		personPicked: function(data) {
			if(data) {
				this.pickedPerson = data;
				this.query.pickedPerson = data;
				this.query.uic = data.uic;
			}
		},
		unitPicked: function(data) {
			if(data) {
				this.baseUnit = data;
				this.query.baseUnit = data;
				this.query.baseUnitId = data.id;
			}
		},
		exportCitations: function() {
			let query = {
				year: this.viewYear,
				uics: [],
			}

			let uics = this.authorStats.map( f => f.author.uic );
			console.log(uics);
			query.uics = uics;

			this.publicationService.downloadPublicationQueryCitations( query, {format: "docx"})
		},
		exportPublications: function() {
			let query = {
				year: this.viewYear,
				uics: [],
			}

			let uics = this.authorStats.map( f => f.author.uic );
			console.log(uics);
			query.uics = uics;

			this.publicationService.downloadPublicationExport( query, {format: "xlsx"})
		},
		download: function(format) {
			this.departmentStore.downloadAuthorStats(this.baseUnit.id, {year: this.viewYear, format: format})
		}
	},
}

</script>;