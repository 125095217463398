<template>
	<v-container fluid>
		<v-toolbar class="mt-2 mb-2 elevation-0" dense>
			<PersonPicker @input="personPicked" :value="publications.pickedAuthor.uic" :value-text="publications.pickedAuthor.displayName" class="mx-2"></PersonPicker>

			<v-select  class="mx-2" v-model="query.year"
					:items="listsStore.yearList"
					label="Rok publikace"
					required
					></v-select>
					<v-btn @click="search()" class="mx-2">Hledat</v-btn>
			<v-spacer></v-spacer>
			<reload-button-component class="mx-2" v-on:reload="load()" />
		</v-toolbar>

		<v-divider></v-divider>

		<v-data-table v-if="{}" :headers="headers" :items="publications.items" :items-per-page="15">
		
		<template v-slot:item.creator="{item}">
		</template>
		<template v-slot:item.source="{item}">
			<div>
			<v-chip v-if="item.wos" x-small :color="getChipColor(item.wosStatusId)" dark>
				<v-icon>mdi-alpha-w</v-icon>
				<v-progress-circular v-if="item.wosStatusId==1" class="ml-1" indeterminate size="15" width="1"></v-progress-circular>
			</v-chip>
			<v-chip class="ml-2" v-if="item.scopus" :color="getChipColor(item.scopusStatusId)" x-small dark>
				<v-icon>mdi-alpha-s</v-icon>
				<v-progress-circular v-if="item.scopusStatusId==1" indeterminate size="15" width="1"></v-progress-circular>
			</v-chip>
			</div>
		</template>

		<template v-slot:item.title="{item}">
			{{ item.title }}
			<v-chip x-small color="green" dark v-if="item.earlyAccess">
				<v-icon>mdi-clock-outline</v-icon>
				Early access</v-chip>
			<v-chip x-small color="gray">{{ item.importDate }}</v-chip>
		</template>

		<template v-slot:item.imported="{item}">
			<v-icon v-if="item.stateId==3 ">mdi-check-circle</v-icon>
			<v-icon v-if="item.stateId==2 ">mdi-progress-helper</v-icon>
		</template>
		<template v-slot:item.actions="{item}">
			<v-progress-circular v-if="item.stateId!=3 && !isImportable(item)" indeterminate color="primary"></v-progress-circular>
			<v-btn  v-if="item.stateId!=3 && isImportable(item)" icon :to="{name: 'import-publication', params: {id: item.id, backTo: {name: 'publications'}}}"><v-icon>mdi-import</v-icon></v-btn>
			<v-btn  v-if="item.stateId==3 && item.publicationId > 0" icon :to="{name: 'publication-detail', params: {id: item.publicationId}}"><v-icon>mdi-book-open-blank-variant</v-icon></v-btn>
		</template>
		</v-data-table>
	</v-container>
</template>

<script> 
//import {axiosInst} from '../main'

import { listsStore } from '../stores/lists-store';
import { authStore } from '../stores/user';
import { useWormEntryStore } from '../stores/worm-entry-store';
import PersonPicker from './parts/PersonPicker.vue';
import ReloadButtonComponent from '@/components/ReloadButtonComponent.vue'

export default {
    name: "ImportPublications",
    props: {
        panelTitle: String,
    },
    data: () => ({
        uic: 0,
        isLoading: false,
        headers: [
            { text: "Id#", value: "id", sortable: false  },
            { text: "Zdroj", value: "source", width: "180px", sortable: false  },
            { text: "Rok", value: "year", sortable: false  },
            { text: "Název", value: "title", sortable: false  },
            { text: "Imp.", value: "imported", sortable: false  },
            { text: "Akce", value: "actions", width: "120px", sortable: false },
        ],
        publications: useWormEntryStore(),
        userStore: authStore(),
		pickedPerson: {},
		query: {
			year: 2022
		},
		listsStore: listsStore(),
    }),
    mounted: async function () {
		await this.listsStore.fetch();
        await this.userStore.verify();

		
		if(!this.publications.pickedAuthor || !this.publications.pickedAuthor.uic) {
			this.publications.pickedAuthor = this.userStore;
		}
		
        this.load();
    },
    methods: {
        load: function () {
            console.log("loading data", this.publications.pickedAuthor);
            this.publications.fetchForAuthor(this.publications.pickedAuthor.uic);
            /*
            axiosInst.get('/import/search/publications?uic='+ this.uic)
                .then( response => {
                    this.publications = response.data.data
            }).finally( () => {
                    this.isLoading = false
            })
            */
        },
		search: function() {
			this.publications.fetchForAuthor(this.publications.pickedAuthor.uic)
		},
		personPicked: function(data) {
			if(data) {
				this.publications.pickedAuthor = data;
				this.query.pickedPerson = data;
				this.query.uic = data.uic;
			}
		},
		getChipColor: function(status) {
			switch(status) {
				case 1:
					return "orange";
				case 2:
					return "green";
				default:
					return "";
			}
		},
		isImportable: function(item) {
			let overallStatus = false;

			if( item.wos && item.wosStatusId == 2 && item.scopus && item.scopusStatusId == 2 ) {
				overallStatus = true;
			} else if ( !item.wos && item.scopus && item.scopusStatusId == 2 ) {
				overallStatus = true;
			} else if ( !item.scopus && item.wos && item.wosStatusId == 2 ) {
				overallStatus = true;
			}

			return overallStatus;
		}
    },
    components: { PersonPicker, ReloadButtonComponent }
}

</script>;