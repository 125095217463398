import { axiosInst } from "../main";
import BaseService from "./base-service";

export default class JournalService extends BaseService {
    
    async fetchAll( params, callback )  {
        const response = await axiosInst.get('/journals', {
            params: params
        })

        this.handleCallback(callback)
        return response
    }

    async get( id, params )  {
        let path = (id) => `/journals/${id}`
        const response = await axiosInst.get( path(id), {
            params: params
        })
        return response
    }
    
    async create( data, params )  {
        let path = `/journals`
        const response = await axiosInst.post( path, data, {
            params: params
        })
        return response
    }

    async update( id, data, params )  {
        let path = `/journals/${id}`
        const response = await axiosInst.put( path, data, {
            params: params
        })
        return response
    }

    async getWorm( id, params )  {
        let path = (id) => `/worm/journals/${id}`
        const response = await axiosInst.get( path(id), {
            params: params
        })
        return response
    }
}