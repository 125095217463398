import Vue from 'vue'
import VueRouter from 'vue-router'
import PublicationList from '../components/PublicationList.vue'
import NewPublicationTypeSelector from '../components/NewPublicationTypeSelector.vue'
import ImportPublication from '../components/ImportPublication.vue'
import ImportPublications from '../components/ImportPublications.vue'
import Settings from '../components/Settings.vue'
import JournalsSettings from '../components/JournalsSettings.vue'
import FordSettings from '../components/FordSettings.vue'
import CepSettings from '../components/CepSettings.vue'
import GrantPublisherSettings from '../components/GrantPublisherSettings.vue'
import GrantSettings from '../components/GrantSettings.vue'
import PatentPublisherSettings from '../components/PatentPublisherSettings.vue'
import SystemListSettings from '../components/SystemListSettings.vue'
import JournalPublishersSettings from '../components/JournalPublishersSettings.vue'
import GrantDetail from '../components/GrantDetail.vue'
import JournalDetail from '../components/JournalDetail.vue'
import Dashboard from '../components/Dashboard.vue'
import PublicationForm from '../components/PublicationForm.vue'
import PublicationSearch from '../components/PublicationSearch.vue'
import DepartmentDetail from '../components/DepartmentDetail.vue'
import FacultyDetail from '../components/FacultyDetail.vue'
import ApprovalManager from '../components/ApprovalManager.vue'
import ApprovalManagerNew from '../components/ApprovalManagerNew.vue'
import ExportRiv from '../components/ExportRiv.vue'
import Login from '../components/Login.vue'
import PublicationDetail from '../components/PublicationDetail.vue'
import RightsSettings from '../components/RightsSettings.vue'
import ApprovalManagerFrame from '../components/ApprovalManagerFrame.vue'
import ApprovalManagerSuperTest from '../components/ApprovalManagerSuperTest.vue'
import UserManager from '../components/UserManager.vue'

import { defineStore } from "pinia";
import {authStore} from '../stores/user.js'
import {notificationStoreStore, useNotificationStore} from '../stores/notification-store'
import { useApplicationStore } from '../stores/application'
//import {APP} from '../main'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'dashboard',
    component: Dashboard,
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      requiresAuth: false,
    }
  },
  {
    path: '/publications',
    name: 'publications',
    component: PublicationSearch,
  },
  {
    path: '/approval',
    name: 'approval',
    component: ApprovalManagerNew,
    meta: {
      roles: ['ADMINISTRATOR', 'PUBLICATION_APPROVAL']
    }
  }, 
  {
    path: '/user-manager',
    name: 'user-manager',
    component: UserManager,
    meta: {
      roles: ['ADMINISTRATOR', 'FACULTY_MANAGER']
    }
  }, 
  {
    path: '/manager',
    name: 'manager',
    component: ApprovalManagerNew,
    meta: {
      roles: ['ADMINISTRATOR', 'PUBLICATION_APPROVAL']
    }
  }, 
  {
    path: '/manager-new-test',
    name: 'manager-new-test',
    component: ApprovalManagerSuperTest,
    meta: {
      roles: ['ADMINISTRATOR', 'PUBLICATION_APPROVAL']
    }
  }, 
  {
    path: '/approval-new',
    name: 'approval-new',
    component: ApprovalManagerFrame,
    meta: {
      roles: ['ADMINISTRATOR', 'PUBLICATION_APPROVAL']
    }
  }, 
{
    path: '/user/publications',
    name: 'user-publications',
    component: PublicationList,
  },
  {
    path: '/publications/select-new',
    name: 'select-new-publication',
    component: NewPublicationTypeSelector
  },
  {
    path: '/publications/new/:type',
    name: 'create-new-publication',
    component: PublicationForm,
    props: (route) => ({isNewEntry: true, isDraft: route.params.isDraft, isImporting: route.params.isImporting, draftEntry: route.params.draftEntry, type: route.params.type, backTo: route.params.backTo})
  },
  {
    path: '/publications/draft/:draft',
    name: 'draft-publication',
    component: PublicationForm,
    props: (route) => ({isNewEntry: true, isDraft: true, isImporting: false, draftEntry: route.params.draft})
  },
  {
    path: '/publications/:id',
    name: 'edit-publication',
    component: PublicationForm,
    props: (route) => ({isNewEntry: false, isDraft: false, isImporting: route.params.isImporting, editEntry: route.params.id})
  },
  {
    path: '/publications/:id/detail',
    name: 'publication-detail',
    component: PublicationDetail,
    props: (route) => ({publicationId: route.params.id, backTo: route.params.backTo})
  },
  // import
  {
    path: '/publication/import',
    name: 'import-publications',
    component: ImportPublications
  },
  {
    path: '/publication/import/:id',
    name: 'import-publication',
    component: ImportPublication,
    props: (route) => ({isNewEntry: true, isImporting: true, importEntry: route.params.id})
  },
  {
    path: '/settings',
    name: 'settings',
    component: Settings,
    meta: {
      roles: ['ADMINISTRATOR']
    }
  },
  {
    path: '/settings/journals',
    name: 'settings-journals',
    component: JournalsSettings,
    meta: {
      roles: ['ADMINISTRATOR']
    }
  },
  {
    path: '/journals/:id',
    name: 'journal-detail',
    component: JournalDetail,
    props: (route) => ({journalId: route.params.id}),
    meta: {
      roles: ['ADMINISTRATOR']
    }
  },
  {
    path: '/settings/ford',
    name: 'settings-ford',
    component: FordSettings,
    meta: {
      roles: ['ADMINISTRATOR']
    }
  },
  {
    path: '/settings/cep',
    name: 'settings-cep',
    component: CepSettings,
    meta: {
      roles: ['ADMINISTRATOR']
    }
  },
  {
    path: '/settings/grant-publishers',
    name: 'settings-grant-publishers',
    component: GrantPublisherSettings,
    meta: {
      roles: ['ADMINISTRATOR']
    }
  },
  {
    path: '/settings/grants',
    name: 'settings-grant',
    component: GrantSettings,
    meta: {
      roles: ['ADMINISTRATOR']
    }
  },
  {
    path: '/grants/:id',
    name: 'grant-detail',
    component: GrantDetail,
    props: (route) => ({grantId: route.params.id}),
    meta: {
      roles: ['ADMINISTRATOR']
    }
  },
  {
    path: '/settings/patent-publishers',
    name: 'settings-patent-publishers',
    component: PatentPublisherSettings,
    meta: {
      roles: ['ADMINISTRATOR']
    }
  },
  {
    path: '/settings/lists',
    name: 'settings-lists',
    component: SystemListSettings,
    meta: {
      roles: ['ADMINISTRATOR']
    }
  },
  {
    path: '/settings/publishers',
    name: 'settings-publishers',
    component: JournalPublishersSettings,
    meta: {
      roles: ['ADMINISTRATOR']
    }
  }, 
  {
    path: '/settings/rights',
    name: 'settings-rights',
    component: RightsSettings,
    meta: {
      roles: ['ADMINISTRATOR']
    }
  }, 
  {
    path: '/departments',
    name: 'departments',
    component: DepartmentDetail,
    meta: {
      roles: ['ADMINISTRATOR', 'DEPARTMENT_MANAGER']
    }
  }, 
  {
    path: '/faculties',
    name: 'faculties',
    component: FacultyDetail,
    meta: {
      roles: ['ADMINISTRATOR', 'FACULTY_MANAGER']
    }
  }, 
  {
    path: '/exports/riv',
    name: 'export-riv',
    component: ExportRiv,
  }, 
]


const router = new VueRouter({
  routes
})

router.beforeEach( async (to, from, next) => {
 // const userStore = authStore()
 
 const routerAuthStore = authStore()
 const notificationStore = useNotificationStore();
 const applicationStore = useApplicationStore();
  
  if( (to.meta && !to.meta.hasOwnProperty("requiresAuth")) || (to.meta && to.meta.requiresAuth === undefined && to.meta.requiresAuth == true) ) {
    await routerAuthStore.verify();
    
    if(routerAuthStore.isAuthorized) {
      if(!applicationStore.initialized) {
        console.log("not initialized")
      }

      if(to.meta.roles && to.meta.roles.length > 0) {
        let roleFound = routerAuthStore.hasRole( to.meta.roles );
        

        if(!roleFound) {
          console.log("forbidden")
          notificationStore.addError({message: "Pro provedení dané akce nemáte dostatečná oprávnění."})
          return;
        }
      }

    } else {
      console.log("invalid auth session - login required", routerAuthStore.isAuthorized )
      router.push({name: "login"})
    }
    
  } else {
    console.log("Router - verify not required", to)
  }

  return next()
})

export default router
