<template>
	
	<v-container flat fluid>
		<v-row justify="space-around">
			<v-col cols="1"></v-col>
			<v-col cols="4">
				<v-form @submit.prevent="login">
				<v-card class="mt-5">
					<v-card-title v-t="'dialogs.login'"></v-card-title>
					<v-card-text>
							<v-text-field :label="$t('persons.username')" v-model="username"></v-text-field>
							<v-text-field type="password" :label="$t('persons.password')" v-model="password"></v-text-field>
						</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn text type="submit" :disabled="!loginEnabled || username == '' || password == ''">{{ $t("btn.login") }} </v-btn>
					</v-card-actions>
				</v-card>
				</v-form>
					<v-sheet flat class="mt-10 grey--text text--lighten-1 text-caption">
					cv: <v-icon class="text-caption">mdi-check</v-icon> ok - v2.0.33258-seqoia<br />
					cv-worker: <v-icon class="text-caption">mdi-check</v-icon> ok <br />
					scopus-import: <v-icon class="text-caption">mdi-check</v-icon> ok <br />
					wos-import: <v-icon class="text-caption">mdi-check</v-icon> ok <br />
					vaWorm: <v-icon class="text-caption">mdi-check</v-icon> ok - v0.0.15<br />
				</v-sheet>
			</v-col>
			<v-col cols="4">
				<v-row dense>
					<v-col cols="12" v-for="(item, index) in news" :key="item.id">
						<v-card :color="item.color" elevation="0" :dark="item.dark" >
							<v-card-title>
								<v-icon large left v-text="item.icon"></v-icon>
								<span class="text-h6 font-weight-light" v-text="item.title"></span>
							</v-card-title>
							<v-card-text v-text="item.text"></v-card-text>
						</v-card>
					</v-col>
					<v-card elevation="0">
						<v-card-title>
							Dokumenty
						</v-card-title>
						<v-card-text>
							<div class="mt-2">
								<v-icon>mdi-file</v-icon> <a href="/docs/CV_2_v1_draft.pdf">Manuál pro uživatele</a>
							</div>
						</v-card-text>
					</v-card>
				</v-row>
			</v-col>
			<v-col cols="1"></v-col>
		</v-row>
	</v-container>

</template>

<script> 
import ReloadButtonComponent from '@/components/ReloadButtonComponent.vue'
import { authStore } from '@/stores/user'
import { useNotificationStore } from '../stores/notification-store';
import { useApplicationStore } from '../stores/application';

export default {
	components: { ReloadButtonComponent },
	name: 'Login',
	data: () => ({
		search: '',
		username: "",
		password: "",
		currentUser: authStore(),
		loginEnabled: true,
		notification: useNotificationStore(),
		applicationStore: useApplicationStore(),
		news: [
//			{id: 15, title: "Upozornění", text: "Dočasné omezení vstupu do aplikace z důvodů exportů do RIV. Přístup povolen pouze správcům. Obnovení běžného provozu očekáváme 17. 2. Omlouváme se za komplikace.", icon: "mdi-alert-circle", dark: true, color: "red"},
//			{id: 14, title: "Upozornění", text: "Dočasné omezení vstupu do aplikace z důvodů kontroly integrity dat. Přístup povolen pouze správcům. V případě potřeby exportů, využijte dočastně původní verze aplikace na adrese http://cv-old.czu.cz. Obnovení běžného provozu. 10. 10. Omlouváme se za komplikace.", icon: "mdi-alert-circle", dark: true, color: "red"},
//			{id: 13, title: "Pilotní provoz", text: "Aplikace je v pilotním provozu. V případné problémy či dotazy zasílejte prostřednictvím aplikace ServiceDesk nebo oddělení Helpdesk.", icon: "mdi-alert-circle", dark: false, color: "blue-lighten-4"},
//			{id: 11, title: "Informace o migraci CV - Probíhá", text: "Konečná kontrola záznamů a počáteční párování publikací s vědeckými databázemi WOS a Sopus. Stahování nových publikací za rok 2022 a 2023.", icon: "mdi-information"},
//			{id: 12, title: "Informace o migraci CV - Dokončeno", text: "Dokončen import publikací z předchozí verze a čištění záznamů", icon: "mdi-information"},
		]
	}),
	mounted: async function() {
		//await this.currentUser.verify()

		
		this.$nextTick( () => {
			console.log(this.currentUser.uic)
			this.load();
		});

		//this.applicationStore.overlayLoading = true
	},
	methods: {
		load: function() {

		},
		login: async function() {
			if(this.username != "" && this.password != "" && this.loginEnabled) {
				let authResult = await this.currentUser.login(this.username, this.password);
				if(authResult) {
					this.notification.addSuccess({message: "Úspěšné přihlášení."})
					this.$nextTick( () => {

						/*
						let roles = this.currentUser.roles.filter( r => r.role == "ADMINISTRATOR" );
						if(roles.length == 0) {
							this.currentUser.logout();
							this.notification.addError({message: "Vstup do systému je aktuálně povolen pouze pro správce"})
							return;
						}
						*/

						this.$router.push({"name": "dashboard"})
					})
				} else {
					this.notification.addError({message: "Bylo zadáno chybné uživatelské jméno nebo heslo."})
				}
			}
		}
	}
}

</script>