<template>
	
    <v-stepper v-model="stepperModel" flat >
        <v-stepper-items>
            <v-stepper-content step="1" class="pa-0">
                <v-container fluid flat>
                    <v-list dense style="height: calc(100vh - 300px); overflow-y: auto;">
                        <v-list-item-group>
                            <v-list-item>
                                <v-list-item-content> </v-list-item-content>
                            </v-list-item>
                        </v-list-item-group>
                        <v-divider></v-divider>
                        <v-subheader>Fakulty</v-subheader>
                        <v-list-item-group>
                            <v-list-item  v-for="faculty in facultyList" :key="faculty.id">
                                <v-list-item-content @click="pickFaculty(faculty, true)">
                                    <v-list-item-title v-text="faculty.name"></v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action @click="pickFaculty(faculty, true)"><v-icon>mdi-chevron-right</v-icon></v-list-item-action>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-container>
            </v-stepper-content>
            <v-stepper-content step="2" class="pa-0">
                <v-container fluid flat>
                    <v-list dense style="height: calc(100vh - 300px); overflow-y: auto;">
                        <v-list-item-group>
                            <v-list-item @click="goBack(1)">
                                <v-list-item-icon><v-icon>mdi-arrow-left</v-icon></v-list-item-icon>
                                <v-list-item-content>Zpět</v-list-item-content>
                            </v-list-item>
                        </v-list-item-group>
                        <v-divider></v-divider>
                        <v-subheader>Útvar</v-subheader>
                        <v-list-item-group>
                            <v-list-item @click="pickDepartment(baseUnit, false)" v-for="baseUnit in baseUnitsList" :key="baseUnit.id">
                                <v-list-item-content >
                                    <v-list-item-title v-text="baseUnit.name"></v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action @click="pickDepartment(baseUnit, true)"><v-icon>mdi-account-details</v-icon></v-list-item-action>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-container>
            </v-stepper-content>
            <v-stepper-content step="3" class="pa-0">
                <v-container fluid flat>
                    <v-list dense style="height: calc(100vh - 300px); overflow-y: auto;">
                        <v-list-item-group>
                            <v-list-item @click="goBack(2)">
                                <v-list-item-icon><v-icon>mdi-arrow-left</v-icon></v-list-item-icon>
                                <v-list-item-content>Zpět</v-list-item-content>
                            </v-list-item>
                        </v-list-item-group>
                        <v-divider></v-divider>
                        <v-subheader>Uživatelé</v-subheader>
                        <v-list-item-group>
                            <v-list-item @click="pickAuthor(author)" v-for="author in authorList" :key="author.uic">
                                <v-list-item-content >
                                    <v-list-item-title v-text="author.displayName"></v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-container>
            </v-stepper-content>
        </v-stepper-items>
    </v-stepper>

</template>

<script>
import DepartmentService from '../../services/department-service';
import { useDepartmentStore } from '../../stores/department-store';
import { listsStore } from '../../stores/lists-store';

export default {
    name: "AuthorPicker",
    props: {
    },
    data: function () {
        return {
            stepperModel: 1,
            listsStore: listsStore(),
            departmentStore: useDepartmentStore(),
            departmentService: new DepartmentService(),
            facultyList: [],
            baseUnitsList: [],
            authorList: [],
            isLoading: false,

            pickedFaculty: {},
            pickedBaseUnit: {},
            pickedAuthor: {},
        };
    },
    mounted: async function() {
        await this.listsStore.fetch();
        this.facultyList = this.listsStore.faculties;
    },
    watchers: {
        listsStore: function(n,o) {
            this.facultyList = this.listsStore.faculties;
        }
    },
    methods: {
        goBack: function(state) {
            this.stepperModel = state
            this.updateRecords()
            this.reportPick()
        },
        updateRecords: function() {
            switch(this.stepperModel) {
                case 1:
                    this.pickedAuthor = {}
                    this.pickedBaseUnit = {}
                    break;
                case 2:
                    this.pickedAuthor = {}
                    break;
            }
        },
        reportPick: function() {
            this.$emit("input", {
                state: this.stepperModel,
                faculty: this.pickedFaculty,
                baseUnit: this.pickedBaseUnit,
                pickedAuthor: this.pickedAuthor
            });
            console.log("reporting pick")
        },
        pickFaculty: async function(fac, goDetail) {
            this.pickedFaculty = fac
            this.baseUnitsList = [];
            this.baseUnitsList = await this.departmentStore.fetchForFaculty(fac.id);

            if(goDetail) {
                this.stepperModel = 2
            }
            this.reportPick();
        },
        pickDepartment: async function(dep, goDetail) {
            this.pickedBaseUnit = dep;

            if(goDetail) {
                this.authorList = [];
                this.departmentService.fetchAuthors(dep.id)
                    .then( r => {
                        this.authorList = r.data.data;
                    } )
                this.stepperModel = 3;
            }

            this.reportPick();
        },
        pickAuthor: function(author) {
            this.pickedAuthor = author
            this.reportPick();
        }
    },
    components: {  }
}
</script>