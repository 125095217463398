<template>
	<span>
        <v-toolbar flat>
            <v-toolbar-title>{{ $t('view.import-author-mapping') }}</v-toolbar-title>
            <v-spacer></v-spacer>
        </v-toolbar>
		<v-simple-table>
			<template v-slot:default>
				<thead>
                    <tr>
                        <th>{{$t("general.order")}}</th>
                        <th>{{$t("persons.displayName")}}</th>
                        <th></th>
                        <th>{{$t("persons.type")}}</th>
                        <th>{{ $t('publication.authors.reprint') }}</th>
                        <th></th>
                        <th>{{ $t('labels.match-found') }}</th>
                        <th>{{ $t('labels.author') }}</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(author, index) in authors" :key="author.id">
                        <td>{{index+1}}</td>
                        <td>
                            {{author.wormAuthor.displayName}}
                        </td>
                        <td>
                            <WormAuthorAffiliation :author="author" class="mt-2"></WormAuthorAffiliation>
                        </td>
                        <td>
                            <v-chip v-if="author.author && author.author.type==1" small >{{$t("persons.internal")}}</v-chip>
                            <v-chip v-if="(author.author && author.author.type==2) || !author.author" small>{{$t("persons.external")}}</v-chip>
                        </td>
                        <td>
                            <v-icon v-if="author.reprint">mdi-star-check</v-icon>
                        </td>
                        <td>
                            <AuthorDepartment v-if="(author.author && author.author.uic>0)" :editable="editable" :author="author.author"></AuthorDepartment>
                        </td>
                        <td>
                            <v-chip v-if="author.locked" color="success" text-color="white">ano</v-chip>
                            <v-chip v-if="!author.locked" color="error" text-color="white">ne</v-chip>
                        </td>
                        <td>
                            <v-chip v-if="author.locked && author.author" color="success" text-color="white"><v-avatar left><v-icon>mdi-checkbox-marked-circle</v-icon></v-avatar>{{author.author.displayName}}</v-chip>
                        </td>
                        <td>
                            <v-btn v-if="!author.locked" small color="primary" @click="authorModalOpen(index)">{{ $t('btn.pick-or-find-author') }}</v-btn>
                            <v-btn v-if="author.locked" small color="orange" @click="cancelAuthorPairing(index)">{{ $t('btn.remove-pairing') }}</v-btn>
                        </td>
                    </tr>
                </tbody>
			</template>
		</v-simple-table>
        <AuthorModal ref="authorModal" @close-dialog="modalClosed"></AuthorModal>
	</span>
</template>

<script>
import DepartmentSearchModal from '../dialogs/DepartmentSearchModal.vue';
import AuthorModal from '../dialogs/AuthorModal.vue';
import AuthorDepartment from './AuthorDepartment.vue';
import { useNotificationStore } from '../../stores/notification-store';
import WormAuthorAffiliation from './WormAuthorAffiliation.vue';

export default {
    name: "ImportAuthorsManager",
    props: {
        errorMessages: Array,
		valueText: String,
        value: Number,
		authors: Array,
        editable: Boolean,
    },
    data: function () {
        return {
            timeoutId: null,
            search: "",
            isLoading: false,
            hasValue: false,
			foundItem: {},
            notifyService: useNotificationStore(),
            activeAuthor: null,
			rules: {
				select: [
					v => !!v || 'Zadejte hledanou hodnotu',
				]
			}
        };
    },
    methods: {
        clear: function () {
            this.hasValue = false;
        },
        getResolvedAuthors: function() {
            let out = [];

            this.authors.forEach( f => {
                if(f.author) {
                    f.author.correspondenceAuthor = f.reprint
                    out.push( f.author )
                }
            } )

            console.log(out)
            return out;
        },  
        cancelAuthorPairing: function(index) {
            console.log(index);
            this.authors[index].locked = false;
        },
		modalOpen: function() {
			this.$refs.search.open()
		},
        updateValue: function () {
            this.$emit("input", this.foundItem);
        },
        authorModalOpen: function(index) {
            this.activeAuthor = index;

            let props = {
                search: this.authors[index].wormAuthor.surname,
                extAuthor: this.authors[index].wormAuthor,
                autoSearch: true
            }

            console.log(props)

            this.$refs.authorModal.open(props)

        },
		modalClosed: function(response) {
			console.log(response)
            
            let person = this.authors[this.activeAuthor]
            
            if(person) {
                let author = {}
                if(response.type == 1) {
                    person.author = response;
                    person.author.wormAuthorId = person.id;
                    person.locked = true
                } else {
                    response.type = 2;
                    person.author = response;
                    person.author.wormAuthorId = person.id;
                    person.locked = true
                }

                console.log(person);
                this.authors.splice(this.activeAuthor, 1, person)
                //this.authors[this.activeAuthor] = person;
                console.log(person);

            } else {
                this.notifyService.addError({message: this.$t('messages.unable-to-add-same-author')})
            }
            
		},
    },
    components: { DepartmentSearchModal, AuthorModal, AuthorDepartment, WormAuthorAffiliation }
}
</script>