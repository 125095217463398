var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"3"}},[_c('author-picker',{model:{value:(_vm.pickerData),callback:function ($$v) {_vm.pickerData=$$v},expression:"pickerData"}})],1),_c('v-divider',{attrs:{"vertical":""}}),_c('v-col',[(_vm.toolbarTitle != '')?_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',{staticClass:"h3"},[_vm._v(_vm._s(_vm.toolbarTitle))]),_c('v-spacer'),_c('v-menu',{staticClass:"ml-2",attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({},'v-btn',attrs,false),on),[_vm._v(" Exporty a sestavy ")])]}}],null,false,272243548)},[_c('v-list',[_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.exportDrafts()}}},[_vm._v(" Exportovat ")])],1)],1)],1):_vm._e(),(!_vm.hasData)?_c('div',[_c('v-alert',{attrs:{"type":"info","dense":"","outlined":""}},[_vm._v("Pro vybrané zobrazení nelze zobrazit data, vyberte podřízenou položku.")])],1):_vm._e(),(_vm.hasData)?_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.publicationStore.publicationDrafts,"items-per-page":15,"show-expand":"","single-expand":""},on:{"item-expanded":_vm.itemExpanded},scopedSlots:_vm._u([{key:"item.state",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":""}},[_vm._v("Draft")])]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":""}},[_vm._v(_vm._s(item.type))])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('publication-view',{attrs:{"approval-enabled":_vm.viewApprovalEnabled,"publication":_vm.publicationDraftView}})],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","target":"_blank","to":{name: 'draft-publication', params: {isDraft: true, draft: item.token, type: item.typeId}}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-pencil")])],1)]}}],null,false,2691845432)}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }