<template>
	
	<v-container flat fluid>
		<v-row>
			<v-col cols="8">
				<v-card>
					<v-card-title>
						{{ $t('view.my-publications') }}
						<v-spacer></v-spacer>
						<v-btn text :to="{name: 'user-publications'}">{{ $t('btn.go-to-my-publications') }}</v-btn>
					</v-card-title>
					<v-card-text>
						<v-simple-table>
							<template>
								<thead>
									<th>{{ $t('publications.name') }}</th>
									<th>{{ $t('publications.type') }}</th>
									<th>{{ $t('publications.state') }}</th>
									<th></th>
								</thead>
								<tbody>
									<tr v-for="item in publicationStore.items">
										<td>{{item.name}}</td>
										<td>
											<v-chip small>{{ item.publicationTypeLabelShort }}</v-chip>
										</td>
										<td>
											<publication-state :stateId="item.publicationStateId" :label="item.publicationState"></publication-state>
										</td>
										<td></td>
									</tr>
									<tr v-if="publicationStore.items.length == 0">
										<td colspan="4">
											{{ $t('messages.no-publications-found-for-given-year') }}
										</td>
									</tr>
								</tbody>
							</template>
						</v-simple-table>
		
					</v-card-text>
				</v-card>
				<v-card class="mt-5">
					<v-card-title>
						{{ $t('view.publications-for-import') }}
						<v-spacer></v-spacer>
						<v-btn text :to="{name: 'import-publications'}">{{ $t('btn.import-publications') }}</v-btn>
					</v-card-title>
					<v-card-text>
						<v-data-table v-if="{}" :headers="headers" :items="importablePublications.items" :items-per-page="15">
							<template v-slot:item.title="{item}">
								{{ item.title }}
								<v-chip x-small color="green" dark v-if="item.earlyAccess">
									<v-icon>mdi-clock-outline</v-icon>
									Early access</v-chip>
							</template>
							<template v-slot:item.imported="{item}">
								<v-icon v-if="item.stateId==3 ">mdi-check-circle</v-icon>
								<v-icon v-if="item.stateId==2 ">mdi-progress-helper</v-icon>
							</template>
							<template v-slot:item.actions="{item}">
								<v-progress-circular v-if="item.stateId!=3 && !isImportable(item)" indeterminate color="primary"></v-progress-circular>
								<v-btn  v-if="item.stateId!=3 && isImportable(item)" icon :to="{name: 'import-publication', params: {id: item.id, backTo: {name: 'publications'}}}"><v-icon>mdi-import</v-icon></v-btn>
								<v-btn  v-if="item.stateId==3 && item.publicationId > 0" icon :to="{name: 'publication-detail', params: {id: item.publicationId}}"><v-icon>mdi-book-open-blank-variant</v-icon></v-btn>
							</template>
						</v-data-table>
					</v-card-text>
				</v-card>
			</v-col>
			<v-col cols="4">
				<v-card>
					<v-card-title>{{ $t('view.current-user') }}</v-card-title>
					<v-card-text>
						<v-text-field readonly label="Autor" v-model="currentUser.displayName"></v-text-field>
						<v-text-field readonly label="Autor - iniciály" v-model="currentUser.initials"></v-text-field>
						<v-text-field readonly label="ORCID" v-model="currentUser.orcId"></v-text-field>
						<v-text-field readonly label="Scopus" v-model="currentUser.scopusId"></v-text-field>
						<v-row>
							<v-col>
								<v-spacer></v-spacer>
								<v-btn @click="editAuthor">{{ $t('btn.update-profile') }}</v-btn>
								<v-spacer></v-spacer>
							</v-col>
						</v-row>
					</v-card-text>
					<v-divider></v-divider>
					<v-row v-if="currentUser.wormAuthor">
						<v-col cols="6" class="text-center">
							<br />
							<v-icon v-if="currentUser.wormAuthor.stateId == 1">mdi-progress-clock</v-icon>
							<v-icon v-if="currentUser.wormAuthor.stateId == 2">mdi-check</v-icon>
							<br />
							<div v-if="currentUser.wormAuthor.stateId == 1">Scopus <br /> zařazování k importu</div>
							<div v-if="currentUser.wormAuthor.stateId == 2">Scopus <br /> import dokončen</div>
							<br />
						</v-col>
						<v-col cols="6" class="text-center">
							<br />
							<v-icon v-if="currentUser.wormAuthor.stateId == 1">mdi-progress-clock</v-icon>
							<v-icon v-if="currentUser.wormAuthor.stateId == 2">mdi-check</v-icon>
							<br />
							<div v-if="currentUser.wormAuthor.stateId == 1">WOS <br /> zařazování k importu</div>
							<div v-if="currentUser.wormAuthor.stateId == 2">WOS <br /> import dokončen</div>
							<br />
						</v-col>
					</v-row>
					<v-divider></v-divider>
					<v-card-text v-if="false">
						<v-card-subtitle>Přehled publikací</v-card-subtitle>
						<v-sheet class="v-sheet--offset mx-auto" color="gray" max-width="calc(100% - 32px)">
							<v-sparkline :labels="graph.labels" :value="graph.value" line-width="2" padding="16" height="50" smooth></v-sparkline>
						</v-sheet>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
		<AuthorEditModal ref="authorModal"></AuthorEditModal>
	</v-container>

</template>

<script> 
import ReloadButtonComponent from '@/components/ReloadButtonComponent.vue'
import AuthorEditModal from '@/components/dialogs/AuthorEditModal.vue'
import { useJournalStore } from '../stores/journal-store'
import { publicationStore } from '../stores/publication-store'
import { authStore } from '../stores/user'
import { useWormEntryStore } from '../stores/worm-entry-store';
import PublicationState from '@/components/parts/PublicationState.vue'

export default {
	components: { ReloadButtonComponent, AuthorEditModal, PublicationState },
	name: 'Dashboard',
	data: () => ({
		search: '',
		journalStore: useJournalStore(),
		graph: {labels: [2018, 2019, 2020, 2021, 2022], value: [5, 0, 0, 5, 5]},
		currentUser: authStore(),
		publicationStore: publicationStore(),
		importablePublications: useWormEntryStore(),
		headers: [
            { text: "Id#", value: "id" },
            { text: "Název", value: "title" },
            { text: "Imp.", value: "imported", sortable: false },
            { text: "", value: "actions", width: "100px", sortable: false },
        ],
	}),
	mounted: async function() {
		//await this.currentUser.verify()

		this.$nextTick( () => {
			console.log(this.currentUser.uic)
			this.load();
		});
	},
	methods: {
		load: function() {
			this.publicationStore.fetch( {uic: this.currentUser.uic} )
			this.importablePublications.fetchForAuthor(this.currentUser.uic);
		},
		copy: function(elm) {
			console.log(elm, elm.modelValue)
		},
		editAuthor: function() {
			this.$refs.authorModal.open({uic: this.currentUser.uic});
		},
		isImportable: function(item) {
			let overallStatus = false;

			if( item.wos && item.wosStatusId == 2 && item.scopus && item.scopusStatusId == 2 ) {
				overallStatus = true;
			} else if ( !item.wos && item.scopus && item.scopusStatusId == 2 ) {
				overallStatus = true;
			} else if ( !item.scopus && item.wos && item.wosStatusId == 2 ) {
				overallStatus = true;
			}

			return overallStatus;
		}
	}
}

</script>