<template>
	<v-card class="pa-4 ma-4">
		<v-card-title>Export - RIV</v-card-title>
		<v-card-text>
			<v-row>
				<v-col>
					<v-select label="Fakulta" v-model="query.facultyId" :items="listsStore.faculties" item-text="abbr" item-value="id" ></v-select>
				</v-col>
				<v-col>
					<v-select label="Poskytovatel" v-model="query.grantProviderId" :items="listsStore.grantProvider" item-text="displayCode" item-value="id"></v-select>
				</v-col>
				<v-col>
					<v-checkbox label="Pouze bodované typy publikací" v-model="query.rankedPublicationTypes"></v-checkbox>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="1">
					<v-checkbox label="1." v-model="q1"></v-checkbox>					
				</v-col>
				<v-col cols="2">
					<v-select label="Rok od" v-model="query.yearFrom" :items="listsStore.yearList"></v-select>
				</v-col>
				<v-col cols="2">
					<v-select label="Rok do" v-model="query.yearTo" :items="listsStore.yearList"></v-select>
				</v-col>
				<v-col cols="2">
					<v-menu ref="approvedFrom" v-model="dateApprovedFrom" :close-on-content-click="false"
						:return-value.sync="dateApprovedFrom" transition="scale-transition" offset-ymin-width="auto">
						<template v-slot:activator="{ on, attrs }">
							<v-text-field v-model="query.approvedFrom" label="Schváleno od" prepend-icon="mdi-calendar" v-bind="attrs" v-on="on"></v-text-field>
						</template>
						<v-date-picker v-model="query.approvedFrom" no-title scrollable>
							<v-spacer></v-spacer>
							<v-btn text color="primary" @click="dateApprovedFrom = false">Cancel</v-btn>
							<v-btn text color="primary" @click="$refs.approvedFrom.save(date)">OK</v-btn>
						</v-date-picker>
					</v-menu>
				</v-col>
				<v-col cols="2">
					<v-menu ref="approvedTo" v-model="dateApprovedTo" :close-on-content-click="false"
						:return-value.sync="dateApprovedTo" transition="scale-transition" offset-ymin-width="auto">
						<template v-slot:activator="{ on, attrs }">
							<v-text-field v-model="query.approvedTo" label="Schváleno do" prepend-icon="mdi-calendar" v-bind="attrs" v-on="on"></v-text-field>
						</template>
						<v-date-picker v-model="query.approvedTo" no-title scrollable>
							<v-spacer></v-spacer>
							<v-btn text color="primary" @click="dateApprovedTo = false">Cancel</v-btn>
							<v-btn text color="primary" @click="$refs.approvedTo.save(date)">OK</v-btn>
						</v-date-picker>
					</v-menu>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="1">
					<v-checkbox label="2." v-model="q2"></v-checkbox>					
				</v-col>
				<v-col cols="2">
					<v-select label="Rok od" v-model="query2.yearFrom" :items="listsStore.yearList"></v-select>
				</v-col>
				<v-col cols="2">
					<v-select label="Rok do" v-model="query2.yearTo" :items="listsStore.yearList"></v-select>
				</v-col>
				<v-col cols="2">
					<v-menu ref="approvedFrom2" v-model="dateApprovedFrom2" :close-on-content-click="false"
						:return-value.sync="dateApprovedFrom2" transition="scale-transition" offset-ymin-width="auto">
						<template v-slot:activator="{ on, attrs }">
							<v-text-field v-model="query2.approvedFrom" label="Schváleno od" prepend-icon="mdi-calendar" v-bind="attrs" v-on="on"></v-text-field>
						</template>
						<v-date-picker v-model="query2.aprovedFrom" no-title scrollable>
							<v-spacer></v-spacer>
							<v-btn text color="primary" @click="dateApprovedFrom2 = false">Cancel</v-btn>
							<v-btn text color="primary" @click="$refs.approvedFrom2.save(date)">OK</v-btn>
						</v-date-picker>
					</v-menu>
				</v-col>
				<v-col cols="2">
					<v-menu ref="approvedTo2" v-model="dateApprovedTo2" :close-on-content-click="false"
						:return-value.sync="dateApprovedTo2" transition="scale-transition" offset-ymin-width="auto">
						<template v-slot:activator="{ on, attrs }">
							<v-text-field v-model="query2.approvedTo" label="Schváleno do" prepend-icon="mdi-calendar" v-bind="attrs" v-on="on"></v-text-field>
						</template>
						<v-date-picker v-model="query2.approvedTo" no-title scrollable>
							<v-spacer></v-spacer>
							<v-btn text color="primary" @click="dateApprovedTo2 = false">Cancel</v-btn>
							<v-btn text color="primary" @click="$refs.approvedTo2.save(date)">OK</v-btn>
						</v-date-picker>
					</v-menu>
				</v-col>
			</v-row>
			<v-row>
				<v-spacer></v-spacer>
				<v-col>
					<v-btn @click="search">Vyhledat</v-btn>
					<v-btn @click="exportXml">Exportovat</v-btn>
				</v-col>
			</v-row>
		</v-card-text>
		<v-card-text>
			<v-data-table :headers="headers" :items="searchResults" :items-per-page="25">
			</v-data-table>
		</v-card-text>
	</v-card>
</template>

<script> 
import {axiosInst} from '../main'
import ReloadButtonComponent from '@/components/ReloadButtonComponent.vue'
import { publicationStore } from '../stores/publication-store'
import { authStore } from '../stores/user'
import DepartmentPicker from './parts/DepartmentPicker.vue'
import { listsStore } from '../stores/lists-store'
import PublicationService from '../services/publication-service'
import { useNotificationStore } from '../stores/notification-store'

export default {
	components: { ReloadButtonComponent, DepartmentPicker },
	name: 'ExportRiv',
	props: {
	},
	data: () => ({
		uic: 0,
		headers: [
			{text: "#", value: 'id'},
			{text: "Název", value: 'name'},
			{text: "Typ", value: 'publicationType'},
			{text: "Stav", value: 'publicationState'},
			{text: "Rok", value: 'yearOfPublication'},
			{text: "Akce", value: 'actions', sortable: false},
		],
		publications: [],
		publicationStore: publicationStore(),
		publicationService: new PublicationService(),
		listsStore: listsStore(),
		userStore: authStore(),
		notificationStore: useNotificationStore(),
		
		baseUnit: {},
		query: {
			yearFrom: 2024,
			yearTo: 2024,
			facultyId: 1,
			grantProviderId: 0,
			rankedPublicationTypes: true,
			approvedFrom: null,
			approvedTo: null,
			publicationStateId: 3,
		},

		query2: {
			yearFrom: 2018,
			yearTo: 2023,
			approvedFrom: "2024-03-20",
			approvedTo: "2025-02-10",
		},

		q1: true,
		q2: false,

		searchResults: [],

		dateApprovedFrom: false,
		dateApprovedTo: false,
		dateApprovedFrom2: false,
		dateApprovedTo2: false,
	}),
	mounted: async function() {
	},
	methods: {
		load: function() {
			//this.publicationStore.fetch( {uic: this.userStore.uic} )
		},
		search: function() {
			this.searchResults = [];

			let dbQuery = this.getQuery();

			this.publicationService.query( dbQuery )
			.then( (r) => {
				this.searchResults = r.data.data;
				this.notificationStore.addSuccess({message: "Search done"})
			})
		},
		getQuery: function() {
			let dbQuery = {...this.query};

			if(this.q1 && !this.q1) {
				
			}
			
			if(!this.q1 && this.q2) {
				dbQuery = {...this.q2}
			}

			if(this.q1 && this.q2) {
				let q1 = this.query;
				let q2 = {...q1, ...this.query2}

				dbQuery.queries = [q1, q2];
				dbQuery.multiquery = true;
			}

			return dbQuery;
		},
		exportXml: function() {
			let dbQuery = this.getQuery();

			this.publicationService.downloadXMLForPublications(dbQuery)
			.then((r) => {
				this.notificationStore.addSuccess({message: "Export done"})
			})
		},
	},
}

</script>;