import { defineStore } from "pinia";
import PersonService from "../services/person-service";

export const usePersonStore = defineStore("personStore", {
    state: () => ({
        persons: [],
        searchQuery: {
			q: null,
		},
        favorites: [],
        recent: [],
        cache: [],
    }),
    getters: {
        getItems() {
            return this.items
        },

    },
    actions: {
        clearList() {
            this.persons = [];
        },
        
        async getPerson(uic) {
            let service = new PersonService();
            return service.get(uic);
        },

        async fetch(params, loadingCallBack ) {
            let service = new PersonService();
            const results = await service.fetchAll(params, loadingCallBack);
            this.persons = results.data.data;
        },

        async fetchNames(params, loadingCallBack ) {
            let service = new PersonService();
            return service.fetchNames(params, loadingCallBack);
        },
    }
})