<template>
    <v-container fluid>
        <v-toolbar flat>
            <v-toolbar-title class="h3" v-if="title != ''">{{ title }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn v-if="editable" class="primary" @click="openModal()">Přidat výdaj</v-btn>
        </v-toolbar>
        <v-list three-line>
            <v-list-item  
            v-for="expense in expenses.expenses" :key="expense.id"
            >
                <v-list-item-avatar>
                    <v-icon>mdi-atom</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title>
                        {{ expense.label }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                        <span class="text--primary"><v-icon small>mdi-cash-register</v-icon> {{ expense.price }} CZK</span> 
                            - <v-icon small>mdi-notebook-outline</v-icon> {{ expense.identifier }} 
                            - <v-icon small>mdi-currency-usd</v-icon> {{ expense.originalPrice }} {{ expense.originalCurrency }}
                    </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action v-if="editable">
                    <v-btn icon @click="openModal(expense)">
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                </v-list-item-action>
            </v-list-item>
        </v-list>
        <v-alert v-if="!expenses.hasExpenses" type="info" outlined dense>
            Publikace nemá evidovány žádné výdaje
        </v-alert>
        <PublicationExpenseEditModal ref="expenses" @close-dialog="modalClosed"></PublicationExpenseEditModal>
    </v-container>
</template>

<script>
import { required } from 'vee-validate/dist/rules';
import PublicationService from '../../services/publication-service'
import PublicationExpenseEditModal from '../dialogs/PublicationExpenseEditModal.vue';

export default {
    name: "PublicationExpensesList",
    props: {
        publication: {
            type: Object,
			required: true,
        },
        title: {
            type: String,
            default: "Výdaje za publikaci",
            required: false,
        },
        editable: {
            type: Boolean,
            default: true,
            required: false
        }
    },
    data: () => ({
        kwords: [],
        publicationService: new PublicationService(),
        expenses: [],
    }),
	watch: {
		publication: function(nVal, oVal) {
			if(nVal != oVal) {
				this.load();
			}
		}
	},
	mounted: function() {
        this.load()
	},
    methods: {
        load: function() {
            console.log("Loading expenses")
            if(this.publication && this.publication.id > 0) {
                this.publicationService.fetchExpenses( this.publication.id )
                    .then(r => {
                        this.expenses = r.data;
                    })
            }
        },
        openModal: function(ex) {
            this.$refs.expenses.open( {publicationId: this.publication.id, expense: ex} );
        },
        modalClosed: function(resp) {
            if(resp && resp.reload && resp.reload == true) {
                this.load();
            }
        }
    },
    components: {PublicationExpenseEditModal}
}
</script>