<template>
	<v-card class="ma-4">
		<v-toolbar flat color="primary" dark>
			<v-toolbar-title>Správa uživatelů</v-toolbar-title>
		</v-toolbar>
		<v-card-text>

		</v-card-text>
		<v-card-text>
			<v-row no-gutters>
				<v-col cols="3">
					<author-picker v-model="pickerData"></author-picker>
				</v-col>
				<v-divider vertical></v-divider>
				<v-col>
					<v-toolbar flat v-if="toolbarTitle != ''">
						<v-toolbar-title class="h3">{{ toolbarTitle }}</v-toolbar-title>
						<v-spacer></v-spacer>
						<v-menu offset-y class="ml-2">
								<template v-slot:activator="{on, attrs}">
									<v-btn v-bind="attrs" v-on="on">
										Exporty a sestavy
									</v-btn>
								</template>
								<v-list>
									<v-list-item link @click="exportImports()">
										Exportovat
									</v-list-item>
								</v-list>
							</v-menu>
					</v-toolbar>
					<div v-if="!hasData">
						<v-alert type="info" dense outlined>Pro vybrané zobrazení nelze zobrazit data, vyberte podřízenou položku.</v-alert>
					</div>
					<v-data-table v-if="hasData" :loading="usersLoading" :headers="headers" :items="authorList" :items-per-page="25">
						<template v-slot:item.actions="{item}">
							<v-btn icon @click="editAuthor(item)"><v-icon>mdi-pen</v-icon></v-btn>
						</template>
					</v-data-table>
				</v-col>
			</v-row>
		</v-card-text>
		<AuthorEditModal ref="authorModal" @close-dialog="authorUpdated"></AuthorEditModal>
	</v-card>
</template>

<script> 
import DepartmentService from '../services/department-service';
import PersonService from '../services/person-service';
import { listsStore } from '../stores/lists-store'
import AuthorPicker from './parts/AuthorPicker.vue';
import AuthorEditModal from '@/components/dialogs/AuthorEditModal.vue'

export default {
	components: { AuthorPicker, AuthorEditModal },
	name: 'UserManager',
	props: {
		value: Object,
	},
	data: () => ({
		usersLoading: false,

		listsStore: listsStore(),
		pickerData: {},
		authorList: [],
		departmentService: new DepartmentService(),
		personService: new PersonService(),
		authorInEdit: {},

		hasData: false,
		toolbarTitle: "",

		headers: [
			{text: "#", value: 'uic'},
			{text: "Příjmení", value: 'surname'},
			{text: "Jméno", value: 'name'},
			{text: "Tituly", value: 'title'},
			{text: "Orcid", value: 'orcId'},
			{text: "Scopus", value: 'scopusId'},
			{text: "WOS", value: 'researcherId'},

			{text: "Akce", value: 'actions', sortable: false},
		],
	}),
	mounted: async function() {
		//await this.listsStore.fetch();

		
	},
	watch: {
		pickerData: function(n,o) {
			console.log("picker picked")
			this.loadUsers( n.baseUnit.id );
		}
	},
	computed: {
		/*
		treeItems: function() {
			
		}
		*/
	},
	methods: {
		loadUsers: async function(id) {

			if(this.pickerData.state == 2 && this.pickerData?.faculty?.id) {
				this.hasData = false;
				this.toolbarTitle = this.pickerData.faculty.name;
			}

			if(this.pickerData.state >= 2) {
				this.hasData = true;
				this.authorList = [];
				this.usersLoading = true;
				this.departmentService.fetchAuthors(id, {expanded: 1})
                .then( r => {
					this.authorList = r.data.data;
					this.usersLoading = false;
                } )
			}
		},
		editAuthor: function(author) {
			this.authorInEdit = author;
			this.$refs.authorModal.open({uic: author.uic});
		},
		authorUpdated: function(data) {
			console.log("b", data);
			if(data) {
				console.log(data)
				this.authorInEdit.scopusId = data.scopusId
				this.authorInEdit.researcherId = data.researcherId
				this.authorInEdit.orcId = data.orcId
			}
		},
		exportImports: function() {
			let query = {
				faculty: this.pickerData.faculty?.id,
				baseUnit: this.pickerData.baseUnit?.id,
				person: this.pickerData.pickedAuthor?.uic
			}

			this.personService.downloadAuthors(query, {format: "xlsx"})
		},
	},
}

</script>;