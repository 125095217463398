<template>
	<v-container fluid>
		<v-row no-gutters>
			<v-col cols="3">
				<author-picker v-model="pickerData"></author-picker>
			</v-col>
			<v-divider vertical></v-divider>
			<v-col>
				<v-toolbar flat v-if="toolbarTitle != ''">
					<v-toolbar-title class="h3">{{ toolbarTitle }}</v-toolbar-title>
					<v-spacer></v-spacer>
					<v-menu offset-y class="ml-2">
							<template v-slot:activator="{on, attrs}">
								<v-btn v-bind="attrs" v-on="on">
									Exporty a sestavy
								</v-btn>
							</template>
							<v-list>
								<v-list-item link @click="exportDrafts()">
									Exportovat
								</v-list-item>
							</v-list>
						</v-menu>
				</v-toolbar>
				<div v-if="!hasData">
					<v-alert type="info" dense outlined>Pro vybrané zobrazení nelze zobrazit data, vyberte podřízenou položku.</v-alert>
				</div>
				<v-data-table v-if="hasData" :headers="headers" 
					:items="publicationStore.publicationDrafts" :items-per-page="15"
					show-expand single-expand
					@item-expanded="itemExpanded"> 
					<template v-slot:item.state="{item}">
						<v-chip small>Draft</v-chip>
					</template>
					<template v-slot:item.type="{item}">
						<v-chip small>{{ item.type }}</v-chip>
					</template>
					<template v-slot:expanded-item="{headers, item}">
						<td :colspan="headers.length">
							<publication-view :approval-enabled="viewApprovalEnabled" :publication="publicationDraftView" ></publication-view>
						</td>
					</template>
					<template v-slot:item.actions="{item}">
						<v-btn icon target="_blank" :to="{name: 'draft-publication', params: {isDraft: true, draft: item.token, type: item.typeId}}"><v-icon class="mr-2">mdi-pencil</v-icon></v-btn>
					</template>
				</v-data-table>
			</v-col>
		</v-row>
	</v-container>
</template>

<script> 
import DepartmentService from '../services/department-service';
import { listsStore } from '../stores/lists-store'
import AuthorPicker from './parts/AuthorPicker.vue';
import PublicationView from './PublicationView.vue'
import { publicationStore } from '../stores/publication-store';
import { authStore } from '../stores/user';
import PublicationService from '../services/publication-service';

export default {
	components: { AuthorPicker, PublicationView },
	name: 'DraftManager',
	props: {
		value: Object,
	},
	data: () => ({
		usersLoading: false,
		expanded: [],
		viewApprovalEnabled: false,

		listsStore: listsStore(),
		userStore: authStore(),
		publicationStore: publicationStore(),

		pickerData: {},
		authorList: [],
		departmentService: new DepartmentService(),
		publicationService: new PublicationService(),
		authorInEdit: {},

		publicationDraftView: {},

		hasData: false,

		toolbarTitle: "",

		headers: [
			{text: "#", value: 'id'},
			{text: "Název", value: 'title'},
			{text: "Stav", value: 'state'},
			{text: "Typ", value: 'type'},
			{text: "", value: 'actions'},
		],
	}),
	mounted: async function() {
		await this.listsStore.fetch();
	},
	watch: {
		pickerData: function(n,o) {
			console.log("picker picked", n, o)
			this.loadUsers();
		}
	},
	computed: {
		/*
		treeItems: function() {
			
		}
		*/
	},
	methods: {
		loadUsers: function() {
			this.hasData = false;
			this.toolbarTitle = "";

			if(this.pickerData.state == 2 && this.pickerData?.faculty?.id) {
				this.hasData = false;
				this.toolbarTitle = this.pickerData.faculty.name;
			}
			if(this.pickerData.state == 2 && this.pickerData?.baseUnit?.id ) {
				this.hasData = true;
				this.publicationStore.fetchDepartmentDrafts( this.pickerData.baseUnit.id )
				this.toolbarTitle = this.pickerData.baseUnit.name;
			}
		},
		itemExpanded: async function( item ) {
			let pub = await this.publicationStore.restoreDraft(item.item.token, {})
				.then(r => {
					item.item = r.data;
					this.publicationDraftView = { ...{}, ...r.data}
			})
		},
		exportDrafts: function() {

			let query = {
				faculty: this.pickerData.faculty?.id,
				baseUnit: this.pickerData.baseUnit?.id,
				person: this.pickerData.pickedAuthor?.uic
			}

			this.publicationService.downloadDrafts(query, {format: "xlsx"})
		},
	},
}

</script>;