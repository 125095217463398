<template>
    <v-dialog
        :value="dialogOpen"
        scrollable
        persistent
        max-width="500px"
        >
        <v-card tile>
            <v-toolbar flat dark color="primary">
                <v-toolbar-title>{{ $t('dialogs.publication-expenses') }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="close()"><v-icon>mdi-close</v-icon></v-btn>
            </v-toolbar>
            <v-card-text>
                <v-form v-model="formValid">
                    <v-text-field required :rules="notEmpty" label="Název grantu" v-model="formItem.name"></v-text-field>
                    <v-text-field label="Název grantu (en)" v-model="formItem.nameEn"></v-text-field>
                    
                    <PersonInput :rules="notEmpty" label="Garant" v-model="formItem.owner.uic" class="mx-2"></PersonInput>

                    <v-text-field required :rules="notEmpty" label="Interní číslo" v-model="formItem.internalNumber"></v-text-field>
                    <v-text-field required :rules="notEmpty" label="Číslo grantu" v-model="formItem.number"></v-text-field>
                    
                    <v-select required :rules="notEmpty" label="Poskytovatel" v-model="formItem.providerId" :items="listsStore.grantProvider" item-text="name" item-value="id"></v-select>
                    <v-select required :rules="notEmpty" label="Zdroj financování" v-model="formItem.fundingSourceId" :items="listsStore.fundingSource"></v-select>

                    <v-row>
                        <v-col cols="6">
                            <v-text-field label="Rok od" v-model="formItem.yearFrom"></v-text-field>        
                        </v-col>
                        <v-col cols="6">
                            <v-text-field label="Rok Do" v-model="formItem.yearTo"></v-text-field>        
                        </v-col>
                    </v-row>
                    <v-switch label="Export pro RIV" v-model="formItem.riv"></v-switch>
                    
                    <v-text-field label="Rozpočet (Kč)" v-model="formItem.budget"></v-text-field>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-btn color="error" @click="close(false)">{{ $t('btn.close') }}</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="success" :disabled="!formValid" @click="saveForm">{{ $t('btn.save') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import GrantService from '../../services/grant-service';
import PublicationService from '../../services/publication-service';
import { listsStore } from '../../stores/lists-store';
import { useNotificationStore } from '../../stores/notification-store';
import { authStore } from '../../stores/user';
import PersonInput from '../parts/PersonInput.vue';

export default {
    name: 'GrantEditModal',
    components: {PersonInput},
    props: {
        panelTitle: {
            type: String,
            default: "Změnit typ publikace",
        },
    },
    data: () => ({
        name: null,
        dialogOpen: false,
        isLoading: false,
        formValid: false,
        userStore: authStore(),
        notificationService: useNotificationStore(),
        grantService: new GrantService(),
        listsStore: listsStore(),
        pickedPerson: {},
        formItem: {owner: {uic: 0}},
        notEmpty: [
            v => !!v || 'Položka je vyžadována',
        ],

        _resolve: null,
        _reject: null,
    }),
    mounted: function() {
        this.dialogOpen = false
    },
    methods: {
        open: function(props) {
            console.log("dialog-open");
            //this.$refs.searchInput.focus()
            this.dialogOpen = true
            
            if(props.grant == null) {
                props.grant = {fundingSourceId: 5, yearFrom: 2024, yearTo: 2024}
            }

            if(props.grant.owner) {
                this.pickedPerson = props.grant.owner
            }

            this.formItem = {...props.grant};

            return new Promise((resolve, reject) => {
                this._resolve = resolve
                this._reject = reject
            })
        }, 
        close: function( resp, data ) {
            this.dialogOpen = false
            this.$emit("close-dialog", data)

            if(resp) {
                this._resolve(true)
            } else {
                this._reject(false)
            }
        },
        personPicked: function(data) {
            console.log("picked person", data)
            this.pickedPerson = data
            this.formItem.owner = data
        },
        saveForm: function() {

            let call;
            if(this.formItem.id > 0) {
                call = this.grantService.update( this.formItem.id, this.formItem );
            } else {
                call = this.grantService.create( this.formItem )
            }
            
            
            call.then( r => {
                this.notificationService.addSuccess({message: "Záznam přidán/upraven."})
                this.close({reload: true});
            } )
            .catch( ex => {
                this.notificationService.addError({message: "Záznam se nepodařilo přidat"})
            } )
        },
        clear: function() {
        }
    }

}
</script>
